export const SEARCH_ACTIONS = {
  get: {
    request: "SEARCH/GET_REQUEST",
    success: "SEARCH/GET_SUCCESS",
    error: "SEARCH/GET_ERROR",
  },
  add: "SEARCH/ADD",
  remove: "SEARCH/REMOVE",
  reset: "SEARCH/RESET"
};
