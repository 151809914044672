import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";
import { COLOR, FONT_FAMILY, FONT_WEIGHT } from "./constants/theme";

export const GlobalStyle = createGlobalStyle`
    ${styledNormalize}
   
    * {        
        &,
        &:before,
        &:after {
            box-sizing: border-box;
        }
        
        &:focus {
          outline: none;
        }
    }

    body {
        font: ${FONT_WEIGHT.NORMAL} 16px / 1.2 ${FONT_FAMILY.THIN};
        overflow-y: auto;
        background-color: ${COLOR.BG_PRIMARY};
          /* width */
        ::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          background: ${COLOR.BG_PRIMARY};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: ${COLOR.BG_SECONDARY};
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: ${COLOR.RIVAL_RED};
        }
    }
    
    html, body {
        &.global-modal-is-open {
        	overflow: hidden;
          position: relative;
          width: 100%;
          height: 100%;
        }
    }

		#root-modal {
			position:relative;
		}
    
    .global-modal-container {
    	
    }
`;

ReactDOM.render(
  <Provider store={store}>
      <GlobalStyle />
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
