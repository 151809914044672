import React, { Component } from "react";
import { connect } from "react-redux";
import PubNub from "pubnub";
import InputPanel from "./InputPanel/InputPanel";
import MessageList from "./MessageList";
import {
  Container,
  WindowHeader,
  WrapPinButton,
  PinChatButton,
  Body,
  Panel,
} from "./styled";
import { ThemeProvider } from "styled-components";
import { chatTheme } from "./theme";
import PropTypes from "prop-types";
import moment from "moment";
import { getUsername, propValueOr } from "../../../helpers/common";
import { withRouter } from "react-router-dom";
import FormFieldKit from "../../kit/Fields/FormField/FormField";

// Icons
import PinIcon from "../../../static/icons/icon-external.svg";

class Chat extends Component {
  static propTypes = {
    theme: PropTypes.oneOf(["white", "black"]),
  };

  static defaultProps = {
    theme: "black",
  };

  constructor(props) {
    super(props);
    this.pubnub = new PubNub({
      publishKey: process.env.REACT_APP_PUBLISH_KEY,
      subscribeKey: process.env.REACT_APP_SUBSCRIBE_KEY,
      uuid: this.props.user.id,
      autoNetworkDetection: true,
      restore: true,
    });

    this.meta = {
      name: getUsername(this.props.user),
      image: propValueOr(this.props, "user.imageInfo.thumbnail", undefined),
      role: "admin",
    };
    this.state = {
      lastMessageWeekday: "",
      messageSentDate: [],
      messages: [],
      timetoken: "",
      scrollPosition: 0,
      scrollToLatest: true,
    };
  }

  componentDidMount() {
    this.updateChat();
    const element = document.getElementById(`chatContainer-${this.props.channel}`);
    element.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    if (this.props.channel !== prevProps.channel) {
      this.leaveChat();
      this.updateChat();
    }
  }

  componentWillUnmount() {
    this.leaveChat();
    window.removeEventListener("scroll", this.handleScroll);
  }

  fetchMessages = (timetoken) => {
    const { channel } = this.props;

    this.pubnub.fetchMessages(
      {
        channels: [channel],
        reverse: false,
        stringifiedTimeToken: true,
        includeMessageActions: true,
        includeMeta: true,
        start: timetoken,
      },
      (status, response) => {
        if (!response) {
          return;
        }

        const channels = response.channels;

        if (channels.length === 0) {
          return;
        }

        const messages = channels[channel];
        const startTimeToken = messages[0].timetoken;
        const endTimeToken = messages[messages.length - 1].timetoken;
        const lastMessageWeekday = endTimeToken;
        const messageSentDate = messages.map((message) =>
          parseInt(message.timetoken.toString().substring(0, 13))
        );
        if (startTimeToken !== this.state.timetoken) {
          this.setState({
            messages: [...messages, ...this.state.messages],
            lastMessageWeekday,
            messageSentDate,
          });
          this.scrollToLatest();
          this.setState({
            timetoken: startTimeToken,
          });
        }
      }
    );
  };

  updateChat = () => {
    const { channel } = this.props;

    if (!channel) {
      return;
    }
    this.subscribe(channel);

    this.pubnub.addListener({
      status: (statusEvent) => {
        if (statusEvent.category === "PNConnectedCategory") {
          this.fetchMessages();
        }
      },
      message: (m) => {
        if (!m.message) {
          return;
        }

        const messages = this.state.messages;

        messages.push({
          actualChannel: m.actualChannel,
          channel: m.channel,
          message: m.message,
          meta: m.userMetadata,
          timetoken: m.timetoken,
          uuid: m.publisher,
        });

        const lastMessageWeekday = m.timetoken;

        this.setState({
          messages,
          lastMessageWeekday,
        });

        this.scrollToBottom();
      },
      messageAction: (m) => {
        if (!m.data) {
          return;
        }
        let messages = this.state.messages;
        const index = messages.findIndex(
          (message) => message.timetoken === m.data.messageTimetoken
        );
        messages[index] = {
          ...messages[index],
          ...(m.data.type === "translated" && { translated: m.data.value }),
          ...(m.data.type === "updated" && { updated: m.data.value }),
          ...(m.data.type === "deleted" && { deleted: m.data.value }),
        };
        this.setState({ messages: messages });
      },
    });

    window.addEventListener("beforeunload", this.leaveChat);
  };

  subscribe = (channel) => {
    this.pubnub.subscribe({
      channels: [channel],
      withPresence: true,
    });
  };

  leaveChat = () => {
    this.pubnub.unsubscribeAll();
  };

  getDate = (timetoken, messageType, index = 0) => {
    const messageWeekday = parseInt(timetoken.toString().substring(0, 13));
    const lastMessageWeekday = parseInt(
      this.state.lastMessageWeekday.toString().substring(0, 13)
    );
    const date = moment(
      new Date(parseInt(timetoken.toString().substring(0, 13)))
    ).fromNow();

    switch (messageType) {
      case "historyMessage":
        if (
          Math.abs(this.state.messageSentDate[index - 1] - messageWeekday) >
          60000
        ) {
          return `${date}`;
        }

        break;
      case "senderMessage":
        if (Math.abs(lastMessageWeekday - messageWeekday) > 60000) {
          return `${date}`;
        }

        break;
      default:
        return;
    }
  };

  scrollToBottom = () => {
    if (!this.state.scrollToLatest) {
      return;
    }
    const element = document.getElementById(`chatContainer-${this.props.channel}`);
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  scrollToLatest = () => {
    const element = document.getElementById(`chatContainer-${this.props.channel}`);
    if (element) {
      element.scrollTop = element.scrollHeight - this.state.scrollPosition;
      this.setState({ scrollPosition: element.scrollHeight });
    }
  };

  handleScroll = () => {
    const element = document.getElementById(`chatContainer-${this.props.channel}`);
    if (element.scrollTop === 0) {
      this.fetchMessages(this.state.timetoken);
    }
  };

  render() {
    const theme = chatTheme[this.props.theme];
    const {
      width,
      height,
      startedAt,
      endedAt,
      disabled,
      channel,
      hideModerator,
      onExpand,
    } = this.props;
    const { scrollToLatest } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Container height={height} width={width}>
          <WindowHeader>
            <FormFieldKit
              label={"Scroll to Latest"}
              checkbox
              name={"scrolltolatest"}
              checked={scrollToLatest}
              onChange={(val) => this.setState({ scrollToLatest: val })}
              isBig
              width={"30%"}
            />
            {!!onExpand && (
              <WrapPinButton>
                <PinChatButton src={PinIcon} onClick={() => onExpand()} />
              </WrapPinButton>
            )}
          </WindowHeader>
          <Body id={`chatContainer-${channel}`}>
            <MessageList
              user={this.props.user}
              pubnub={this.pubnub}
              channel={channel}
              hideModerator={hideModerator}
              messages={this.state.messages}
              startedAt={
                startedAt ? moment(new Date(startedAt)).fromNow() : null
              }
              endedAt={endedAt ? moment(new Date(endedAt)).fromNow() : null}
              getDate={this.getDate}
            />
          </Body>

          <Panel>
            {!disabled && (
              <InputPanel
                uuid={this.props.user.id}
                meta={this.meta}
                pubnub={this.pubnub}
                channel={channel}
                scrollToBottom={this.scrollToBottom}
                moderatorAt={this.props.moderatorAt}
                showModeratorDialog={this.props.showModeratorDialog}
              />
            )}
          </Panel>
        </Container>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.userState.info,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chat));
