import axios from "axios";
import { parseJwt } from "../helpers/common";
import { setAuthorizedUserInfoAction } from "../store/user/actions";
import { USER_ACTIONS } from "../store/user/constants";

export default class API {
  constructor(store, options = {}) {
    this.store = store;
    this.client = axios.create({
      baseURL: process.env.REACT_APP_DEVELOPMENT + "api/v1/moderator",
    });
    this.token = options.token || window.localStorage.getItem("jwt");
    this.refreshToken =
      options.refreshToken || window.localStorage.getItem("jwt_refresh");

    this.client.interceptors.request.use(
      (config) => {
        if (!this.token) {
          return config;
        }

        const newConfig = {
          headers: {},
          ...config,
        };

        newConfig.headers.Authorization = `Bearer ${this.token}`;

        return newConfig;
      },
      (err) => Promise.reject(err)
    );

    this.client.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err?.response?.status === 500) {
          this.store.dispatch({
            type: USER_ACTIONS.request.status,
            payload: { status: err.response.status },
          });
        }

        if (
          !this.refreshToken ||
          (err.response && err.response.status !== 401) ||
          err.config.retry
        ) {
          throw err;
        }

        return this.updateTokens(err.config);
      }
    );
  }

  setTokens = async (token, refreshToken) => {
    this.token = token;
    this.refreshToken = refreshToken;
    window.localStorage.setItem("jwt", token);
    window.localStorage.setItem("jwt_refresh", refreshToken);

    // update user information in the redux store.
    this.store.dispatch(setAuthorizedUserInfoAction(parseJwt(token)));
  };

  updateTokens = async (lastRequestConfig) => {
    // if some requests fire together
    if (!this.refreshRequest) {
      this.refreshRequest = this.client.post("refresh_token", {
        refresh_token: this.refreshToken,
      });
    }

    return this.refreshRequest
      .then((resp) => {
        this.setTokens(resp?.data?.access_token, resp?.data?.refresh_token);

        this.refreshRequest = null;

        if (!lastRequestConfig) {
          return;
        }

        const repeatedRequest = {
          ...lastRequestConfig,
          retry: true,
        };

        return this.client(repeatedRequest);
      })
      .catch(() => {
        this.logout();
      });
  };

  /* User login */
  login = async ({ username, password, fingerprint }) => {
    return this.client.post("login", {
      username,
      password,
      fingerprint,
    });
  };

  /* User logout */
  logout = async () => {
    this.token = null;
    this.refreshToken = null;
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem("jwt_refresh");
    window.location.replace("/");
  };

  /* Action APIs */
  action = {
    list: async (body) => {
      return this.client.post(`actions`, body);
    },
  };

  /* Chat APIs */
  chat = {
    translate: async (body) => {
      return this.client.post(`chat`, body);
    },
  };

  /* Match APIs */
  match = {
    get: async (token, body) => {
      return this.client.post(`matches/${token}`, body);
    },
    update: async (token, body) => {
      return this.client.put(`matches/${token}`, body);
    },
  };

  /* Search APIs */
  search = async (query, status) => {
    return this.client.get(
      `search?q=${query}&page=1&${
        status ? `status=${status}&limit=100` : "limit=10"
      }`
    );
  };

  /* Tournament APIs */
  tournament = {
    get: async (token, body) => {
      return this.client.get(`tournaments/${token}`);
    },
    update: async (id, body) => {
      return this.client.put(`tournaments/${id}`, body);
    },
    users: async (id, body) => {
      return this.client.post(`tournaments/${id}/users`, body);
    },
  };

  /* User APIs */
  user = {
    update: async (body) => {
      return this.client.put("user", body);
    },
  };
}
