import React, { useState } from "react";
import { getUsername, propValueOr } from "../../../helpers/common";
import styled, { css } from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 8px;
`;

const Navigation = styled.div`
  display: flex;
  border-radius: 8px 8px 0 0;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 30px;

  border: 1px solid
    ${({ isActive, isHome }) =>
      isActive ? (isHome ? COLOR.NAVY_BLUE : COLOR.ROUGE) : COLOR.BG_SECONDARY};
  background-color: ${({ isActive, isHome }) =>
    isActive ? (isHome ? COLOR.NAVY_BLUE : COLOR.ROUGE) : COLOR.BG_PRIMARY};

  color: ${COLOR.WHITE};
  font: ${FONT_WEIGHT.MEDIUM} 14px / 21px ${FONT_FAMILY.THIN};
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  cursor: pointer;

  ${({ first }) =>
    first &&
    css`
      border-top-left-radius: 8px;
    `}
  ${({ last }) =>
    last &&
    css`
      border-top-right-radius: 8px;
    `}
`;

const WrapStream = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.3636%;

  background-color: ${COLOR.BG_ACCENT};

  color: ${COLOR.TEXT_4};
  font: 12px / 1.2 ${FONT_FAMILY.THIN};
  text-align: center;

  border-radius: 0 0 8px 8px;
`;

const Stream = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;

const Content = styled.div`
  margin: auto;
`;

const Icon = styled.div`
  width: 42px;
  height: 41px;
  margin: 0 auto 14px;
`;

const Title = styled.div`
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
`;

const Text = styled.div`
  max-width: 220px;

  a {
    color: #1065fb;

    &:hover {
      text-decoration: none;
    }
  }
`;

const IFrame = styled.iframe`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;
`;

export default function Streams({ game }) {
  const [notFound, setNotFound] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [streamTabIndex, onChangeStreamTab] = useState(0);
  if (!game?.users) {
    return null;
  }
  const twitchUsername = game.users[streamTabIndex].twitchUsername;
  const streamUrl =
    game.users[streamTabIndex].streamBaseUrl ||
    (twitchUsername &&
      `https://player.twitch.tv/?channel=${twitchUsername}&parent=${window.location.hostname}`);

  if (!streamUrl && !hasFetched) {
    setHasFetched(true);
    setNotFound(true);
  }

  if (streamUrl && !hasFetched) {
    setHasFetched(true);
    fetch(streamUrl)
      .then((resp) => {
        if (resp.status === 404) {
          setNotFound(true);
        }
      })
      .catch((err) => console.log("fetch failed"));
  }

  return (
    <Container>
      <Navigation>
        {game.users.map((p, i) => {
          return (
            <Item
              onClick={() => {
                onChangeStreamTab(i);
                setNotFound(false);
                setHasFetched(false);
              }}
              isActive={i === streamTabIndex}
              isHome={p.isHome}
              key={i}
              first={i === 0}
              last={i === game.users.length - 1}
            >
              {propValueOr(
                p,
                "team.name",
                getUsername(
                  p,
                  propValueOr(game, "tournament.MatchTemplate.username")
                )
              )}
            </Item>
          );
        })}
      </Navigation>
      <WrapStream>
        {!notFound ? (
          <IFrame src={streamUrl} />
        ) : (
          <Stream>
            <Content>
              <Icon />
              <Title>Stream not found</Title>
              {
                <Text>
                  Need Help?
                  <a href="https://support.rivalgames.com/support/solutions/articles/64000240486-how-do-i-stream-my-game-in-the-rival-games-app-">
                    Click Here
                  </a>
                  for Troubleshooting tips
                </Text>
              }
            </Content>
          </Stream>
        )}
      </WrapStream>
    </Container>
  );
}
