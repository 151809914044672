import React from "react";
import styled from "styled-components";
import { COLOR } from "../../../constants/theme";
import { connect } from "react-redux";
import Item from "./Item/Item";

const Container = styled.div`
  border-radius: 12px;
  display: flex;
  min-height: 80px;
  height: 80px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 8px 30px rgb(0 0 0 / 10%), 0px 3px 8px rgb(0 0 0 / 5%);
  background-color: ${COLOR.BG_ACCENT};
  align-items: center;
  color: #f2f2f2;
  transition: all 0.25s ease 0s;
  overflow-x: auto;
    /* width */
    ::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_PRIMARY};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_SECONDARY};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

const Bar = ({ items }) => {
  return (
    <Container>
      {items.map((item, i) => (
        <Item item={item} key={i}></Item>
      ))}
    </Container>
  );
};
const mapStateToProps = (state) => ({
  items: state.searchState.items,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Bar);
