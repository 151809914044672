import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { COLOR, FONT_WEIGHT, FONT_FAMILY } from "../../../constants/theme";
import Item from "./Item/Item";
import { connect } from "react-redux";
import { listActionsAction } from "../../../store/action/actions";
import Pusher from "pusher-js";

const Container = styled.div`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 350px;
  min-width: 350px;
  padding: 10px;
  margin-right: 10px;
  box-shadow: 0px 8px 30px rgb(0 0 0 / 10%), 0px 3px 8px rgb(0 0 0 / 5%);
  background-color: ${COLOR.BG_ACCENT};
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  color: #f2f2f2;
  transition: all 0.25s ease 0s;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  width: 100%;
/* width */
::-webkit-scrollbar {
    width: 16px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_ACCENT};
    border: solid 6px ${COLOR.BG_ACCENT};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_SECONDARY};
    border-radius: 18px;
    border: solid 6px ${COLOR.BG_ACCENT};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-end;
  user-select: none;
  width: 100%;
  min-height: 40px;

  color: ${COLOR.TEXT};
  overflow: hidden;
  margin-bottom: 10px;

  border: 2px solid ${COLOR.RIVAL_RED};
  border-radius: 8px;
`;

const ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.RIVAL_RED};
  color: ${COLOR.WHITE};
`;

const NOT_ACTIVE_SWITCHER_ITEM_STYLES = css`
  background-color: ${COLOR.BG_SECONDARY};
  color: ${COLOR.WHITE};
`;

export const FilterItem = styled.div`
  width: 100%;
  padding: 12px 10px;

  font: ${FONT_WEIGHT.EXTRA_BOLD} 12px ${FONT_FAMILY.CONDENSED};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.3s;

  ${_IS_ACTIVE_SWITCHER_ITEM_};

  &:hover {
    ${ACTIVE_SWITCHER_ITEM_STYLES};
  }

  &:not(:first-child) {
    margin-left: -1px;
  }
`;

function _IS_ACTIVE_SWITCHER_ITEM_({ isActive }) {
  return isActive
    ? ACTIVE_SWITCHER_ITEM_STYLES
    : NOT_ACTIVE_SWITCHER_ITEM_STYLES;
}

const pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
  cluster: "us3",
  forceTLS: true,
});

const Queue = ({ actions, items, listActions }) => {
  const [status, setStatus] = useState("open");
  const [action, setAction] = useState(null);

  useEffect(() => {
    const channel = pusher.subscribe("actions");

    channel.bind("new-action", function (data) {
      setAction(data?.message);
    });
  }, []);

  useEffect(() => {
    if (action) {
      listActions({
        status: status,
        tournamentIds: items?.filter((i) => i.status)?.map((i) => i.id),
        organizationIds: items?.filter((i) => !i.status)?.map((i) => i.id),
      });
      setAction(null);
    }
  }, [action, status, items, listActions]);

  useEffect(() => {
    listActions({
      status: status,
      tournamentIds: items?.filter((i) => i.status)?.map((i) => i.id),
      organizationIds: items?.filter((i) => !i.status)?.map((i) => i.id),
    });
  }, [status, items, listActions]);

  return (
    <Container>
      <Filter>
        <FilterItem
          key={"open"}
          isActive={status === "open"}
          onClick={() => setStatus("open")}
        >
          OPEN TICKETS
        </FilterItem>
        <FilterItem
          key={"closed"}
          isActive={status === "closed"}
          onClick={() => setStatus("closed")}
        >
          CLOSED
        </FilterItem>
      </Filter>
      <List>
        {actions.map((a) => (
          <Item data={a} key={a?.id} />
        ))}
      </List>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  actions: state.actionState.actions,
  items: state.searchState.items,
});

const mapDispatchToProps = {
  listActions: listActionsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Queue);
