import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { layoutLoggedRoutes } from "./routes";

class AuthorizedLayoutRouting extends Component {
  render() {
    const { user } = this.props;
    return (
      <Switch>
        <Route
          render={props => (
            <Fragment>
              {user.requestStatus === 500 ? (
                <></>
              ) : user.requestStatus === 404 ? (
                <></>
              ) : (
                <Switch>
                  {layoutLoggedRoutes.map(route => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        render={props => (
                          <route.component
                            {...props}
                            user={user}
                          />
                        )}
                      />
                    );
                  })}
                </Switch>
              )}
            </Fragment>
          )}
        />
      </Switch>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedLayoutRouting);
