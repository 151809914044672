import { ACTIONS_ACTIONS } from "./constants";

export const listActionsAction =
  (query) =>
  (dispatch, getState, { api }) =>
    api.action
      .list(query)
      .then((resp) => {
        dispatch({ type: ACTIONS_ACTIONS.list.success, payload: resp.data });
        return resp;
      })
      .catch((err) => {
        dispatch({ type: ACTIONS_ACTIONS.list.error });
        throw err;
      });
