import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { COLOR, FONT_FAMILY } from "../../../constants/theme";
import { IconClose, IconCrossPlatform, IconList, IconSearch } from "../../../icons";
import { addSearchItem, resetSearchAction, searchAction } from "../../../store/search/actions";
import moment from "moment";
import breakpoints from "../../../helpers/breakpoints";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Bar = styled.div`
  border-radius: 28px;
  z-index: 2;
  display: flex;
  height: 50px;
  min-height: 50px;
  width: 100%;
  box-shadow: 0px 8px 30px rgb(0 0 0 / 10%), 0px 3px 8px rgb(0 0 0 / 5%);
  background-color: ${COLOR.BG_ACCENT};
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  color: #f2f2f2;
  padding: 13px 10px;
  overflow: hidden;
  transition: all 0.25s ease 0s;
  border: ${({ error }) =>
    error ? `2px solid #ed5f74` : "2px solid transparent"};
`;

const SearchIcon = styled(IconSearch)`
  width: 24px;
  height: 24px;
  fill: #f2f2f2;
  margin-left: 10px;
`;

const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  color: inherit;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  appearance: none;
  margin-left: 5px;
`;

const Items = styled.div`
  z-index: 999;
  position: absolute;
  margin-top: 60px;
  overflow-y: auto;
  height: calc(100vh - 80px);
  width: 100%;
  padding-bottom: 5px;
`;

const Item = styled.div`
  border-radius: 13px;
  display: flex;
  height: 95px;
  min-height: 50px;
  width: 100%;
  box-shadow: 0px 8px 30px rgb(0 0 0 / 10%), 0px 3px 8px rgb(0 0 0 / 5%);
  background-color: ${COLOR.BG_SECONDARY};
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  color: #f2f2f2;
  padding: 0px 20px;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  :hover {
    background-color: ${COLOR.BG_THIRD};
  }
`;

const WrapImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  ${breakpoints.down("xs")} {
    display: none;
  }
`;

const Image = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`;

const WrapConsole = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 10px;
`;

const CrossPlatformIcon = styled(IconCrossPlatform)`
  height: 18px;
  object-fit: contain;
`;

const Console = styled.img`
  height: 18px;
  object-fit: contain;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  ${breakpoints.down("xs")} {
    margin-left: 0px;
  }
`;

const Title = styled.div`
  font-family: ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT};
  font-weight: 600;
  font-size: 20px;
  ${breakpoints.down("xs")} {
    font-size: 18px;
  }
`;

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT};
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 10px;
  ${breakpoints.down("xs")} {
    font-size: 14px;
  }
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 85px;
  height: 23px;
  margin-right: 10px;

  padding: 0px 13px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: 600 15px ${FONT_FAMILY.CONDENSED};
  ${breakpoints.down("xs")} {
    font-size: 12px;
  }
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "initialized":
        return COLOR.BLUE;
      case "in progress":
        return COLOR.GREEN;
      case "complete":
        return COLOR.GREY;
      default:
        return COLOR.BG_INPUT;
    }
  }};
`;

const Backdrop = styled.div`
  visibility: ${({ query }) => (query ? "visible" : "hidden")};
  flex-direction: column;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${({ query }) =>
    query ? " rgba(0, 0, 0, 0.5)" : " rgba(0, 0, 0, 0);"};
  transition: all 0.25s ease 0s;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.RIVAL_RED};
  height: 30px;
  width: 34px;
  border-radius: 30px;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  user-select: none;
  :hover {
    background-color: ${COLOR.RIVAL_RED_2};
  }
`;

const ListIcon = styled(IconList)`
  width: 18px;
  height: 18px;
  fill: ${COLOR.WHITE};
`;

const CloseIcon = styled(IconClose)`
  width: 18px;
  height: 18px;
  fill: ${COLOR.WHITE};
`;

const Search = ({ doSearch, docs, doAdd, doReset }) => {
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState(undefined);

  useEffect(() => {
    if (query || status) {
      doSearch(query, status);
    } else {
      doReset();
    }
  }, [query, status, doSearch, doReset]);

  return (
    <Container>
      <Bar>
        <SearchIcon />
        <Input
          onChange={(e) => {
            setQuery(e.target.value);
            setStatus(undefined);
          }}
          placeholder="Search and pin by community name(s) or by a tournament (name, ID#, or token #)"
          type="text"
          value={query}
        />
        <Button
          onClick={() => {
            status ? setStatus(undefined) : setStatus("in progress");
          }}
        >
          {status ? <CloseIcon /> : <ListIcon />}
        </Button>
      </Bar>
      {(query || status) && (
        <Items>
          {docs?.map((item, i) => (
            <Item
              key={i}
              onClick={() => {
                doAdd(item);
                setQuery("");
                setStatus(undefined);
                doReset();
              }}
            >
              <WrapImage>
                <Image src={item?.imageInfo?.icon} />
              </WrapImage>
              <Info>
                <Title>
                  {item?.status && `ID #${item?.id}: `}
                  {item?.name}
                </Title>
                {item?.status && (
                  <Subtitle>
                    <WrapConsole>
                      {item?.Console?.imageInfo ? (
                        <Console src={item?.Console?.imageInfo?.thumbnail} />
                      ) : (
                        <CrossPlatformIcon />
                      )}
                    </WrapConsole>
                    <Badge status={item?.status}>{item?.status}</Badge>{" "}
                    <Badge>
                      {moment(item?.startDate).format("M/DD/YY @ h:mm A")}
                    </Badge>
                  </Subtitle>
                )}
              </Info>
            </Item>
          ))}
        </Items>
      )}
      <Backdrop query={query || status} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  docs: state.searchState.docs,
  page: state.searchState.page,
  total: state.searchState.total,
});

const mapDispatchToProps = {
  doSearch: searchAction,
  doReset: resetSearchAction,
  doAdd: addSearchItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
