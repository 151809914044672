import { PANELS_ACTIONS } from "./constants";

const initialState = {
  items: [],
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case PANELS_ACTIONS.add:
      if (
        action.payload?.dataType === "chat" &&
        state.items
          .filter((i) => i?.dataType === "chat")
          .map((i) => i?.id)
          .includes(action.payload?.id)
      ) {
        return state;
      }
      if (
        action.payload?.dataType === "stream" &&
        state.items
          .filter((i) => i?.dataType === "stream")
          .map((i) => i?.id)
          .includes(action.payload?.id)
      ) {
        return state;
      } else if (
        (action.payload?.dataType !== "chat" &&
          action.payload?.dataType !== "stream" &&
          !action.payload?.match?.token &&
          state.items
            .filter((i) => i?.tournament?.id && !i?.match?.token)
            .map((i) => i?.tournament?.id)
            .includes(action.payload?.tournament?.id)) ||
        (state.items
          .filter((i) => i?.match?.token)
          .map((i) => i?.match?.token)
          .includes(action.payload?.match?.token) &&
          action.payload?.dataType !== "stream")
      ) {
        return state;
      }
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case PANELS_ACTIONS.remove:
      return {
        ...state,
        items: state.items.filter((i) =>
          action?.payload?.dataType === "stream"
            ? i?.id !== action?.payload?.id
            : action?.payload?.dataType === "chat"
            ? i?.id !== action?.payload?.id
            : action?.payload?.match
            ? i?.match?.token !== action.payload?.match?.token
            : i?.dataType === "chat" ||
              i?.tournament?.id !== action.payload?.tournament?.id
        ),
      };
    default:
      return state;
  }
};

export default reducers;
