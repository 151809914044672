import React from "react";
import AuthorizedLayoutRouting from "./AuthorizedLayoutRouting";
import NotAuthorizedLayoutRouting from "./NotAuthorizedLayoutRouting";

function AppRouting({ user }) {
  return user.logged ? (
    <AuthorizedLayoutRouting user={user} />
  ) : (
    <NotAuthorizedLayoutRouting user={user} />
  );
}

export default AppRouting;
