import { USER_ACTIONS } from "./constants";
import { parseJwt } from "../../helpers/common";

export const loginAction =
  (body) =>
  (dispatch, getState, { api }) =>
    api
      .login(body)
      .then((resp) => {
        dispatch(
          setLoggedInAction(
            resp.data["access_token"],
            resp.data["refresh_token"]
          )
        );
        return resp;
      })
      .catch((err) => {
        dispatch({ type: USER_ACTIONS.login.error });
        throw err;
      });

export const setLoggedInAction =
  (accessToken, refreshToken) =>
  (dispatch, getState, { api }) => {
    api.setTokens(accessToken, refreshToken);
    dispatch({ type: USER_ACTIONS.login.success });
    dispatch(setAuthorizedUserInfoAction(parseJwt(accessToken)));
  };

export const setAuthorizedUserInfoAction =
  (info = {}) =>
  (dispatch) => {
    // check if user has current org for permissions
    dispatch({
      type: USER_ACTIONS.login.setAuthorizedUserInfo,
      payload: { info: info },
    });
  };

export const updateUserInfoAction =
  (body) =>
  (dispatch, getState, { api }) => {
    return api.user
      .update(body)
      .then((resp) => {
        const accessToken = resp.data["access_token"];
        dispatch({ type: USER_ACTIONS.update.success });
        dispatch(setAuthorizedUserInfoAction(parseJwt(accessToken)));
        return resp;
      })
      .catch((err) => {
        dispatch({ type: USER_ACTIONS.update.error });
        throw err;
      });
  };

export const logoutAction =
  () =>
  (dispatch, getState, { api }) => {
    api.logout();
    return setUserAsNotAuthorizedAction();
  };

export const updateTokensAction =
  () =>
  (dispatch, getState, { api }) =>
    api.updateTokens();

export const setUserAsNotAuthorizedAction = () => ({
  type: USER_ACTIONS.login.setUserAsNotAuthorized,
});

export const updateUserRequestStatus = (status) => ({
  type: USER_ACTIONS.request.status,
  payload: { status: status },
});
