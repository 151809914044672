import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  SelectDropDownOptionIconSD,
  SelectDropDownOptionSD,
  SelectDropDownOptionTextSD,
  SelectFieldKitSD,
  SelectFieldMultipleValueLabelSD,
  SelectFieldMultipleValueRemoveSD,
  SelectFieldMultipleValueSD,
  SelectFieldPlaceholderKitSD,
  SelectFieldValueKitSD,
  SelectFieldWrapMultipleValueSD,
  SelectKitSD,
  SelectPopupKitSD,
  SelectSearchFieldWrapSD,
  SelectWrapPopupKitSD
} from "./styled";
import MenuKit from "../../Menu/MenuKit";
import { PopupBodyKitSD } from "../../Popup/styled";
import PopupKit from "../../Popup/PopupKit";
import { IconSelectSD } from "../../Icon/IconSelectSD";
import IconPlus from "../../Icon/IconPlus";
import IconSearch from "../../Icon/IconSearch";
import { InputKitSD } from "../styled";

class SelectKit extends Component {
  /*
   * todo: закрытие и открытие при фокусе на элемент (не выйдет так как попап не находится с полем в одном род элементе, можно просто добовлять обработчик на клик по tab когда попап открык)
   *
   * */

  /*
   * todo: добавить возможность передать options массивом простых значений типа [1,2,3,4]
   * */
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired
      })
    ),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onChange: PropTypes.func,
    /**
     * It imitation of event blur when popup is closed.
     * It doesn't pass any argument
     */
    onBlur: PropTypes.func,
    fullWidth: PropTypes.bool,
    /**
     * If true icon isn't displayed
     */
    hiddenIcon: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,

    /** If this callback is set, a search field will appear in the drop-down block in which the searchValue property will be processed */
    onChangeSearchValue: PropTypes.func,
    /*It's used to control the search field in the drop-down block*/
    searchValue: PropTypes.string,
    /* A placeholder of search field*/
    searchPlaceholder: PropTypes.string,
    hasError: PropTypes.bool
  };
  static defaultProps = {
    placeholder: "Select...",
    width: 200
  };

  anchorEl = React.createRef();

  state = {
    isOpen: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.popupRef.forceUpdate();
    }
  }

  handleClickToField = () => {
    if (this.props.disabled) return;

    this.toggle();
  };
  handleSelectValue = (optionValue, isSelectedOption) => {
    const { onChange, multiple, value } = this.props;

    if (!multiple) {
      this.setState({ isOpen: false });
    }

    if (typeof onChange === "function" && !isSelectedOption) {
      const newValue = multiple ? [...value, optionValue] : optionValue;
      onChange(newValue);
    }
  };
  removeSelectedMultipleOption = selectedValue => {
    const { onChange, value } = this.props;
    const newValue = value.filter(v => v !== selectedValue);
    onChange(newValue);
  };

  isSelectedOption = option => {
    const { multiple, value } = this.props;

    if (multiple) {
      return value.includes(option.value);
    }

    return option.value === value;
  };

  open = () => this.setState({ isOpen: true });
  close = () => {
    const { onBlur } = this.props;

    if (this.state.isOpen) {
      this.setState({ isOpen: false });

      if (typeof onBlur === "function") {
        onBlur();
      }
    }
  };
  toggle = () => (this.state.isOpen ? this.close() : this.open());

  renderField = () => {
    const { hiddenIcon, disabled, hasError } = this.props;
    const { isOpen } = this.state;

    return (
      <SelectFieldKitSD
        tabIndex="0"
        isOpen={isOpen}
        ref={this.anchorEl}
        onClick={this.handleClickToField}
        disabled={disabled}
        hasError={hasError}
      >
        {this.renderPlaceholderOrValue()}

        <IconSelectSD hiddenIcon={hiddenIcon} />
        {this.renderDropDown()}
      </SelectFieldKitSD>
    );
  };
  renderPlaceholderOrValue = () => {
    const { value, options, placeholder, multiple } = this.props;

    if ((multiple && !value.length) || value == null) {
      return (
        <SelectFieldPlaceholderKitSD>{placeholder}</SelectFieldPlaceholderKitSD>
      );
    }

    if (multiple) {
      return this.renderMultipleValue();
    }

    const selectedOption =
      value != null && options.find(option => option.value === value);
    const text = selectedOption ? selectedOption.label : value;
    return <SelectFieldValueKitSD>{text}</SelectFieldValueKitSD>;
  };
  renderMultipleValue = () => {
    const { value, options, disabled } = this.props;

    return (
      <SelectFieldWrapMultipleValueSD>
        {value.map(selectedOptValue => {
          const selectedItem = options.find(o => o.value === selectedOptValue);
          const itemText = selectedItem ? selectedItem.label : selectedOptValue;

          return (
            <SelectFieldMultipleValueSD key={selectedOptValue}>
              <SelectFieldMultipleValueLabelSD>
                {itemText}
              </SelectFieldMultipleValueLabelSD>
              {!disabled && (
                <SelectFieldMultipleValueRemoveSD
                  onClick={e => {
                    e.stopPropagation();
                    this.removeSelectedMultipleOption(selectedOptValue);
                  }}
                >
                  <IconPlus />
                </SelectFieldMultipleValueRemoveSD>
              )}
            </SelectFieldMultipleValueSD>
          );
        })}
      </SelectFieldWrapMultipleValueSD>
    );
  };
  renderDropDown = () => {
    const {
      options,
      searchValue,
      searchPlaceholder,
      onChangeSearchValue
    } = this.props;
    const { isOpen } = this.state;
    const hasSearchField = typeof onChangeSearchValue === "function";

    return (
      <PopupKit
        isOpen={isOpen}
        onClose={this.close}
        moveShadowDown={true}
        ref={el => (this.popupRef = el)}
        disabledPortal
      >
        <SelectWrapPopupKitSD>
          <SelectPopupKitSD>
            <PopupBodyKitSD>
              {hasSearchField && (
                <SelectSearchFieldWrapSD>
                  <InputKitSD
                    type={"text"}
                    value={searchValue}
                    onChange={e => onChangeSearchValue(e.target.value)}
                    placeholder={searchPlaceholder}
                  />

                  <IconSearch width={20} />
                </SelectSearchFieldWrapSD>
              )}

              {options.length > 0 && (
                <MenuKit items={options} propNameForText={"label"}>
                  {this.renderDropDownOption}
                </MenuKit>
              )}
            </PopupBodyKitSD>
          </SelectPopupKitSD>
        </SelectWrapPopupKitSD>
      </PopupKit>
    );
  };
  renderDropDownOption = option => {
    const { multiple } = this.props;
    const isSelectedOption = this.isSelectedOption(option);

    return (
      <SelectDropDownOptionSD
        isActive={isSelectedOption}
        onClick={() => this.handleSelectValue(option.value, isSelectedOption)}
      >
        <SelectDropDownOptionTextSD isActive={isSelectedOption}>
          {option.label}
        </SelectDropDownOptionTextSD>

        {multiple && !isSelectedOption && (
          <SelectDropDownOptionIconSD>
            <IconPlus />
          </SelectDropDownOptionIconSD>
        )}
      </SelectDropDownOptionSD>
    );
  };

  render() {
    const { fullWidth, width } = this.props;

    return (
      <SelectKitSD fullWidth={fullWidth} width={width}>
        {this.renderField()}
      </SelectKitSD>
    );
  }
}

export default SelectKit;
