import React, { useRef } from "react";
import PropTypes from "prop-types";

import {
  FormFieldKitSD,
  FieldWrapperKitSD,
  FormFieldAdditionalKitSD,
  FormFieldErrorKitSD,
  FormFieldLabelFieldGroupKitSD,
  FormFieldLabelCustomIconWrapSD,
  FormFieldWrapMessagesSD,
  Description
} from "./styled";
import { InputKitSD, LabelKitSD } from "../styled";
import SelectKit from "../Select/SelectKit";
import DateTimePickerKit from "../DateTimePicker/DateTimePickerKit";
import CheckboxKit from "../Checkbox/CheckboxKit";
import { COLOR } from "../../../../constants/theme";

function FormFieldKit(props) {
  const {
    label,
    icon,
    iconAlign,
    onChange,
    placeholder,
    value,
    fullWidth,
    width,
    additionalText,
    errorText,
    multiline,
    horizontalLabel,
    horizontalLabelWidth,
    horizontalLabelBox,
    disabled,
    inputProps,
    select,
    selectOptions,
    maxIconWidth,
    dateTimePicker,
    name,
    checkbox,
    defaultChecked,
    checked,
    children,
    selectMultiple,
    type,
    max,
    min,
    step,
    minDate,
    maxDate,
    required,
    inputStyles,
    gutterBottom,
    onChangeSearchValue,
    searchValue,
    searchPlaceholder,
    onBlur,
    inputFocus,
    bgColor,
    fontSize,
    isBig,
    description
  } = props;

  const canDrawIcon = !multiline && icon;
  const totalWidth = horizontalLabel ? width + horizontalLabelWidth : width;
  const hasError = !!errorText;

  const inputRef = useRef(null);
  const handleLabelClick = () => {
    inputRef.current && inputRef.current.focus();
  };

  return (
    <FormFieldKitSD
      fullWidth={fullWidth}
      totalWidth={totalWidth}
      gutterBottom={gutterBottom}
      hasError={hasError}
    >
      {checkbox ? (
        <CheckboxKit
          defaultChecked={defaultChecked}
          value={value}
          name={name}
          label={label}
          checked={checked}
          onChange={onChange && (e => onChange(e.target.checked))}
          onBlur={onBlur}
          disabled={disabled}
          isBig={isBig}
        />
      ) : (
        <FormFieldLabelFieldGroupKitSD
          horizontalLabel={horizontalLabel}
          horizontalLabelWidth={horizontalLabelWidth}
          horizontalLabelBox={horizontalLabelBox}
          totalWidth={totalWidth}
          fullWidth={fullWidth}
        >
          {label && (
            <LabelKitSD onClick={handleLabelClick}>
              {label}{" "}
              {required && (
                <span style={{ color: COLOR.RIVAL_RED, fontWeight: "bold" }}>
                  *
                </span>
              )}
            </LabelKitSD>
          )}

          {description != null && <Description>{description}</Description>}

          {children || (
            <FieldWrapperKitSD hasIcon={!!icon} iconAlign={iconAlign}>
              {select && selectOptions ? (
                <SelectKit
                  fullWidth
                  multiple={selectMultiple}
                  value={value}
                  hiddenIcon={canDrawIcon}
                  placeholder={placeholder}
                  options={selectOptions}
                  disabled={disabled}
                  onChange={onChange}
                  onBlur={onBlur}
                  searchValue={searchValue}
                  onChangeSearchValue={onChangeSearchValue}
                  searchPlaceholder={searchPlaceholder}
                  hasError={hasError}
                />
              ) : dateTimePicker ? (
                <DateTimePickerKit
                  placeholder={placeholder}
                  onChange={onChange && (dateNum => onChange(dateNum))}
                  value={value}
                  disabled={disabled}
                  hiddenIcon={canDrawIcon}
                  maxDate={maxDate}
                  minDate={minDate}
                  fullWidth
                  hasError={hasError}
                  iconAlign={iconAlign}
                />
              ) : (
                <InputKitSD
                  ref={inputRef}
                  type={type}
                  max={max}
                  min={min}
                  step={step}
                  name={name}
                  style={inputStyles}
                  placeholder={placeholder}
                  onChange={onChange && (e => onChange(e.target.value))}
                  onBlur={onBlur}
                  value={value}
                  multiline={multiline}
                  as={multiline ? "textarea" : undefined}
                  disabled={disabled}
                  hasError={hasError}
                  bgColor={bgColor}
                  fontSize={fontSize}
                  {...(inputFocus ? { autoFocus: true } : {})}
                  {...inputProps}
                />
              )}
              {canDrawIcon && (
                <FormFieldLabelCustomIconWrapSD
                  disabled={disabled}
                  maxIconWidth={maxIconWidth}
                  iconAlign={iconAlign}
                >
                  {icon}
                </FormFieldLabelCustomIconWrapSD>
              )}
            </FieldWrapperKitSD>
          )}
        </FormFieldLabelFieldGroupKitSD>
      )}

      <FormFieldWrapMessagesSD ml={horizontalLabel && horizontalLabelWidth}>
        {additionalText && (
          <FormFieldAdditionalKitSD>{additionalText}</FormFieldAdditionalKitSD>
        )}

        {errorText && <FormFieldErrorKitSD>{errorText}</FormFieldErrorKitSD>}
      </FormFieldWrapMessagesSD>
    </FormFieldKitSD>
  );
}

FormFieldKit.propTypes = {
  /**
   * The custom field which is wrapped styled FormField component
   * there isn't an icon, placeholder and the others field's property
   * */
  children: PropTypes.node,

  checkbox: PropTypes.bool, // todo: implement selecting a field option using the "variable" property (PropTypes.oneOf["input, textarea, date, select, checkbox, radio"])
  select: PropTypes.bool,
  multiline: PropTypes.bool,

  dateTimePicker: PropTypes.bool,
  /**only for datepicker*/
  minDate: PropTypes.number,
  /**only for datepicker*/
  maxDate: PropTypes.number,

  /** props for checkbox */
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,

  type: PropTypes.string,
  label: PropTypes.node,
  /**max value for type number*/
  max: PropTypes.string,
  /**min value for type number*/
  min: PropTypes.string,
  /**value of step for type number*/
  step: PropTypes.string,
  name: PropTypes.string, // todo: so far only used in the checkbox component, implement to others fields
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  /** don't draw when multiline is true */
  icon: PropTypes.node,
  iconAlign: PropTypes.oneOf(["left", "right"]),
  placeholder: PropTypes.string,
  additionalText: PropTypes.node,
  errorText: PropTypes.node,
  /**
   * width of parent block by pixels
   * if horizontalLabel is true total width sums from width and horizontalLabelWidth
   * */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  horizontalLabel: PropTypes.bool,
  horizontalLabelBox: PropTypes.bool,
  horizontalLabelWidth: PropTypes.number,
  /** it disables property of width and makes width 100% */
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  selectOptions: (props, propName, componentName) => {
    if (props.select && !Array.isArray(props[propName])) {
      return new Error(
        "Invalid prop `" +
          propName +
          "` supplied to" +
          " `" +
          componentName +
          "`.If select property equals true there should be array Validation failed."
      );
    }
  },
  selectMultiple: PropTypes.bool,
  maxIconWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputStyles: PropTypes.object,
  /**
   *  if true the field
   * */
  required: PropTypes.bool,
  /*
   * by default it is 12 px.
   * */
  gutterBottom: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),

  // for select search field
  /** If this callback is set, a search field will appear in the drop-down block in which the searchValue property will be processed */
  onChangeSearchValue: PropTypes.func,
  /*It's used to control the search field in the drop-down block*/
  searchValue: PropTypes.string,
  /* A placeholder of search field*/
  searchPlaceholder: PropTypes.string,

  /*blur handler for inputs, it does'n work for select and date picker because they don't have blur event yet */
  onBlur: PropTypes.func,
  fontSize: PropTypes.oneOf(["small", "normal", "large"]),
  isBig: PropTypes.bool
};

FormFieldKit.defaultProps = {
  horizontalLabelWidth: 110,
  width: 300,
  selectOptions: [],
  gutterBottom: 12,
  iconAlign: "right",
  fontSize: "normal",
  isBig: false
};

export default FormFieldKit;
