import { PANELS_ACTIONS } from "./constants";

export const addPanelItem =
  (data) =>
  (dispatch, getState, { api }) =>
    dispatch({ type: PANELS_ACTIONS.add, payload: data });

export const removePanelItem =
  (data) =>
  (dispatch, getState, { api }) =>
    dispatch({ type: PANELS_ACTIONS.remove, payload: data });
