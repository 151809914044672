import styled from 'styled-components';
import breakpoints from '../helpers/breakpoints';
import { COLOR } from '../constants/theme';
import IconClose from './kit/Icon/IconClose';
import { getAspectRatio } from '../helpers/common';

export default function Video({ url, onClose }) {
  if (typeof window === 'undefined' || url === null) {
    return <></>;
  }
  return (
    <WrapVideo>
      <WrapClose onClick={onClose}>
        <CloseIcon />
      </WrapClose>
      <VideoComponent
        src={url}
        autoPlay
        muted
        playsInline
        loop
        controls
        alt={`Video`}
      />
    </WrapVideo>
  );
}

const WrapVideo = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2025;
  right: 12px;
  bottom: 12px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  ${breakpoints.down('xs')} {
    margin: 0 auto;
    width: calc(100% - 24px);
  }
`;

const VideoComponent = styled.video`
  width: 100%;
  border-radius: 8px;
  ${getAspectRatio(16, 9)}
  object-fit: contain;
  border: 1px solid ${COLOR.BORDER};
  background: ${COLOR.BG_PRIMARY};
`;

const WrapClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  z-index: 2026;
  border-radius: 40px;
  width: 35px;
  height: 35px;
  margin-right: 8px;
  margin-top: 8px;
  background-color: ${COLOR.BLACK_2};
  cursor: pointer;
  @media (hover: hover) {
    :hover {
      background-color: ${COLOR.BLACK_3};
    }
  }
  :active {
    background-color: ${COLOR.BLACK_3};
  }
`;

const CloseIcon = styled(IconClose)`
  width: 22px;
  height: 22px;
`;
