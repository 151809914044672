import React from "react";
import styled from "styled-components";
import { COLOR, FONT_FAMILY } from "../../../../constants/theme";
import { connect } from "react-redux";
import { removeSearchItem } from "../../../../store/search/actions";
import { IconCrossPlatform, IconRemove } from "../../../../icons";
import { addPanelItem } from "../../../../store/panel/actions";

const Container = styled.div`
  border-radius: 8px;
  display: flex;
  min-width: 320px;
  height: 100%;
  margin-right: 10px;
  background-color: ${COLOR.BG_SECONDARY};
  align-items: center;
  color: #f2f2f2;
  padding: 0px 10px;
  position: relative;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  user-select: none;
  :hover {
    background-color: ${COLOR.BG_THIRD};
  }
`;

const RemoveIcon = styled(IconRemove)`
  width: 24px;
  height: 24px;
  fill: ${COLOR.ROUGE};
  margin-left: 10px;
  cursor: pointer;
  position: absolute;
  top: -8px;
  right: -8px;
`;

const WrapImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;

const Title = styled.div`
  font-family: ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT};
  font-weight: 600;
  font-size: 14px;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WrapConsole = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: 10px;
`;

const Console = styled.img`
  min-height: 14px;
  height: 14px;
  max-height: 14px;
  object-fit: contain;
`;

const WrapOrganization = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Organization = styled.img`
  max-height: 18px;
  object-fit: contain;
`;

const CrossPlatformIcon = styled(IconCrossPlatform)`
  height: 18px;
  object-fit: contain;
`;

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT};
  font-weight: 400;
  font-size: 13px;
  margin-top: 5px;
  max-width: 250px;
`;

const WrapInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Item = ({ item, addItem, doRemove }) => {
  return (
    <Container>
      <WrapImage>
        <Image src={item?.imageInfo?.icon} />
      </WrapImage>
      <Info
        onClick={() => {
          const tournament = item?.status ? { tournament: item } : null;
          if (tournament) {
            addItem(tournament);
          }
        }}
      >
        <Title>
          {item?.status && `ID #${item?.id}: `}
          {item?.name}
        </Title>
        {item?.status && (
          <Subtitle>
            <WrapInfo>
              {item?.organization && (
                <WrapOrganization>
                  <Organization src={item?.organization?.imageInfo?.icon} />
                </WrapOrganization>
              )}
              <WrapConsole>
                {item?.Console?.imageInfo ? (
                  <Console src={item?.Console?.imageInfo?.thumbnail} />
                ) : (
                  <CrossPlatformIcon />
                )}
              </WrapConsole>
            </WrapInfo>
          </Subtitle>
        )}
      </Info>
      <RemoveIcon onClick={() => doRemove(item)} />
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  doRemove: removeSearchItem,
  addItem: addPanelItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
