import RouteHome from "../../routes/home/RouteHome";
import RouteDashboard from "../../routes/dashboard/RouteDashboard";
import RoutePopout from "../../routes/popout/RoutePopout";

// for not authorized user
/**unauthorized users will redirect to the login page if unauthorized routes do not have the same path*/
export const layoutLoggedRoutes = [
  {
    exact: true,
    path: "/",
    component: RouteDashboard
  },
  {
    exact: true,
    path: "/popout/:type/:token",
    component: RoutePopout
  },
];

// for not authorized user
export const layoutGeneralRoutes = [
  {
    exact: true,
    path: "/",
    component: RouteHome
  },
];
