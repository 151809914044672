import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { COLOR } from "../../constants/theme";
import Bar from "./Bar/Bar";
import Panel from "./Panel/Panel";
import Queue from "./Queue/Queue";
import Search from "./Search/Search";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
  height: calc(100% - 150px);
  max-height: 100%;
`;

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(32em, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(500px, 1fr));
  overflow-y: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 25px;
    height: 25px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_PRIMARY};
    border: solid 8px ${COLOR.BG_PRIMARY};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_SECONDARY};
    border-radius: 18px;
    border: solid 8px ${COLOR.BG_PRIMARY};
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

const Dashboard = ({ panels }) => {
  return (
    <Container>
      <Search />
      <Bar />
      <Content>
        <Queue />
        <Grid>
          {panels?.map((action) => (
            <Panel
              key={
                action?.dataType === "chat"
                  ? `chat-${action?.tournament?.token}`
                  : action?.match?.token || action?.tournament?.token
              }
              action={action}
            />
          ))}
        </Grid>
      </Content>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  panels: state.panelState.items,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
