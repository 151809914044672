import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { connect } from "react-redux";
import { COLOR, FONT_FAMILY } from "../../../constants/theme";
import Chat from "../../../components/smart/Chat/Chat";
import { addPanelItem, removePanelItem } from "../../../store/panel/actions";
import { api } from "../../../store";
import Pusher from "pusher-js";
import {
  getUsername,
  parseTournamentId,
  propValueOr,
} from "../../../helpers/common";
import IconPlus from "../../../components/kit/Icon/IconPlus";
import {
  IconCompact,
  IconCrossPlatform,
  IconPause,
  IconPlay,
  IconSearch,
  IconStream,
  IconWindow,
  IconVideo
} from "../../../icons";
import Timer from "./Timer";
import Skeleton from "react-loading-skeleton";
import { updateTournamentAction } from "../../../store/tournament/actions";
import IconForfeit from "../../../static/icons/icon-forfeit.svg";
import IconReview from "../../../static/icons/icon-review.svg";
import IconSwap from "../../../static/icons/icon-swap.svg";
import IconFlag from "../../../static/icons/icon-flag.svg";
import IconThumb from "../../../static/icons/icon-thumb-up.svg";
import IconChallenge from "../../../static/icons/icon-whistle.svg";
import IconTrophy from "../../../static/icons/icon-trophy.svg";
import IconPlayers from "../../../static/icons/icon-players.svg";
import IconChevronDown from "../../../static/icons/icon-chevron-arrow-down.svg";
import IconExternal from "../../../static/icons/icon-external.svg";
import placeholderUserImg from "../../../static/images/default-user.jpg";
import moment from "moment";
import Streams from "./Streams";
import Video from "../../../components/Video";

const Container = styled.div`
  border-radius: ${({ popout }) => (popout ? "0px" : "20px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  flex: 1;
  box-shadow: 0px 8px 30px rgb(0 0 0 / 10%), 0px 3px 8px rgb(0 0 0 / 5%);
  background-color: ${COLOR.BG_SECONDARY};
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  color: #f2f2f2;
  min-height: 500px;
  overflow: hidden;
  transition: all 0.25s ease 0s;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Section = styled.div`
  border-radius: 8px;
  display: flex;
  width: 100%;
  color: #f2f2f2;
  position: relative;
  transition: all 0.25s ease 0s;
  user-select: none;
`;

const Accordion = styled.div`
  display: flex;
  justify-content: flex-start;
  transition: all 0.25s ease 0s;
  width: 100%;
  background-color: ${COLOR.BG_ACCENT};
  height: ${({ isOpen, collapsed }) =>
    isOpen ? (collapsed ? "265px" : "calc(100% - 150px)") : "0px"};
  max-height: ${({ isOpen, collapsed }) =>
    isOpen ? (collapsed ? "265px" : "calc(100% - 150px)") : "0px"};
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
`;

const HeaderBackground = styled.img`
  width: 100%;
  height: 78px;
  object-fit: cover;
  position: absolute;
  opacity: 0.3;
`;

const ButtonClose = styled.div`
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 5px;
  width: 30px;
  min-width: 30px;
  position: absolute;
  margin-left: calc(100% - 60px);
  height: 30px;
  background-color: ${COLOR.BG_ACCENT};

  svg {
    transform: rotate(45deg);
    fill: ${COLOR.FG_PRIMARY};
    height: 19px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const ButtonWindow = styled.div`
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 5px;
  width: 30px;
  min-width: 30px;
  position: absolute;
  margin-left: calc(100% - 100px);
  height: 30px;
  background-color: ${COLOR.BG_ACCENT};

  &:hover {
    opacity: 0.8;
  }
`;

const Bar = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 0;
  width: 100%;
  overflow-x: auto;
  background-color: ${COLOR.BG_THIRD};
`;

const WrapImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  min-width: 48px;
`;

const Image = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
`;

const WrapUpload = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 10px;
  height: 36px;
  width: 36px;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${COLOR.GREY_2};
`;

const Upload = styled.img`
  height: 140px;
  width: 180px;
  overflow: hidden;
  cursor: zoom-in;
  min-height: 140px;
  min-width: 180px;
  object-fit: contain;
`;

const WrapTitle = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-family: ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT};
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  cursor: ${({ tournament }) => (tournament ? "pointer" : "inherit")};
  :hover {
    text-decoration: ${({ tournament }) =>
      tournament ? "underline" : "inherit"};
  }
`;

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT};
  font-weight: 400;
  font-size: 18px;
  white-space: nowrap;
  margin-top: 5px;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 85px;
  height: 23px;
  margin-right: 10px;

  padding: 0px 13px;
  border-radius: 12px 12px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font: 600 15px ${FONT_FAMILY.CONDENSED};
  text-transform: uppercase;

  background-color: ${({ status }) => {
    switch (status) {
      case "initialized":
        return COLOR.BLUE;
      case "in progress":
        return COLOR.GREEN;
      case "playing":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "challenged":
        return COLOR.YELLOW;
      case "complete":
        return COLOR.GREY;
      case "cancelled":
        return COLOR.TWITCH_PURPLE;
      case "match":
        return COLOR.ORANGE;
      case "tournament":
        return COLOR.TWITCH_PURPLE;
      default:
        return COLOR.TWITCH_PURPLE;
    }
  }};
`;

const WrapRound = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 180px;
`;

const Round = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  height: 20px;

  padding: 3px 6px;
  border-radius: 12px 12px;

  overflow: hidden;
  background-color: ${COLOR.BG_INPUT};
`;

const GameStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  height: 20px;

  padding: 0px 6px;
  border-radius: 12px 12px;

  overflow: hidden;
  font-size: 13px;
  text-transform: uppercase;
  background-color: ${({ status }) => {
    switch (status) {
      case "initialized":
        return COLOR.BLUE;
      case "in progress":
        return COLOR.GREEN;
      case "playing":
        return COLOR.GREEN;
      case "pending":
        return COLOR.YELLOW;
      case "challenged":
        return COLOR.ORANGE;
      case "complete":
        return COLOR.GREY;
      case "cancelled":
        return COLOR.TWITCH_PURPLE;
      default:
        return COLOR.BG_INPUT;
    }
  }};
`;

const WrapSubmitted = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  background-color: ${COLOR.BG_PRIMARY};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
  padding-left: 5px;

  cursor: pointer;
  :hover {
    opacity: 0.8;
  }

  font: 600 15px ${FONT_FAMILY.CONDENSED};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  :first-child {
    border-bottom: 1px solid ${COLOR.BG_SECONDARY};
  }
`;

const RowScore = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  margin-left: 3px;
  border-right: 3px solid
    ${({ outcome }) =>
      outcome === "W"
        ? COLOR.GREEN
        : outcome === "T"
        ? COLOR.YELLOW
        : COLOR.ROUGE};
`;

const WrapInfo = styled.div`
  display: flex;
  align-items: center;
`;

const WrapTimer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 0 3px;
`;

const WrapConsole = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Console = styled.img`
  min-height: 15px;
  height: 15px;
  max-height: 15px;
  object-fit: contain;
`;

const WrapOrganization = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 10px;
`;

const Organization = styled.img`
  max-height: 20px;
  object-fit: contain;
`;

const CompactIcon = styled(IconCompact)`
  fill: ${COLOR.WHITE};
  height: 20px;
  width: 20px;
  margin-right: 5px;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  :hover {
    fill: ${COLOR.RIVAL_RED};
  }
`;

const WindowIcon = styled(IconWindow)`
  fill: ${COLOR.WHITE};
  height: 20px;
  width: 20px;
`;

const CrossPlatformIcon = styled(IconCrossPlatform)`
  height: 18px;
  object-fit: contain;
`;

const StreamsIcon = styled(IconStream)`
  height: 20px;
  object-fit: contain;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  :hover {
    fill: ${COLOR.RIVAL_RED};
  }
`;

const PlayIcon = styled(IconPlay)`
  width: 18px;
  height: 16px;
  margin-right: -2px;
  fill: ${COLOR.WHITE};
`;

const PauseIcon = styled(IconPause)`
  width: 18px;
  height: 18px;
  fill: ${COLOR.WHITE};
  margin-right: 2px;
`;

const WrapChevron = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  background-color: ${COLOR.BG_ACCENT};
  :hover {
    background-color: ${COLOR.BG_THIRD};
  }
`;

const ChevronIcon = styled.img`
  width: 30px;
  height: 30px;
  transform: rotate(${({ collapsed }) => (collapsed ? "0deg" : "180deg")});
  transition: all 0.5s;
`;

const WrapChat = styled.div`
  position: relative;
  flex-direction: column;
  display: ${({ collapsed }) => (collapsed ? "none" : "flex")};
  width: 100%;
  height: 50px;
  flex: 1;
  padding-bottom: 10px;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  fill: ${COLOR.WHITE};
  margin-right: 5px;
`;

const WrapExternalIcon = styled.a``;

const ExternalIcon = styled.img`
  width: 16px;
  height: 16px;
  fill: ${COLOR.WHITE};
  margin-left: 8px;
  cursor: pointer;
`;

const FlagIcon = styled.img`
  width: 13px;
  height: 12px;
  fill: ${COLOR.WHITE};
  margin-right: 5px;
  margin-left: 3px;
`;

const SwapIcon = styled.img`
  width: 18px;
  height: 20px;
  fill: ${COLOR.WHITE};
  margin-left: -2px;
`;

const ChallengeIcon = styled.img`
  width: 20px;
  height: 20px;
  padding-top: 1px;
  margin-left: -1px;
  fill: ${COLOR.WHITE};
`;

const Text = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.RIVAL_RED};
  border-radius: 4px;
  padding: 5px 5px;
  margin-left: 10px;
  height: 100%;
  max-height: 30px;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  user-select: none;
  :hover {
    background-color: ${COLOR.RIVAL_RED_2};
  }
`;

const ButtonStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: ${({ status, active }) =>
    !active
      ? "transparent"
      : status === "initialized"
      ? COLOR.BLUE
      : status === "playing"
      ? COLOR.GREEN
      : status === "pending"
      ? COLOR.YELLOW
      : status === "challenged"
      ? COLOR.ORANGE
      : status === "cancelled"
      ? COLOR.TWITCH_PURPLE
      : COLOR.GREY};
  border: 1px solid
    ${({ status }) =>
      status === "initialized"
        ? COLOR.BLUE
        : status === "playing"
        ? COLOR.GREEN
        : status === "pending"
        ? COLOR.YELLOW
        : status === "challenged"
        ? COLOR.ORANGE
        : status === "cancelled"
        ? COLOR.TWITCH_PURPLE
        : status === "complete"
        ? COLOR.GREY
        : COLOR.RIVAL_RED};
  border-radius: 4px;
  padding: 2px 5px;
  margin-left: 10px;
  height: 100%;
  min-height: 20px;
  max-height: 30px;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  user-select: none;
  :hover {
    opacity: 0.8;
    background-color: ${({ status }) => !status && COLOR.RIVAL_RED};
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
`;

const ButtonSubmit = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR.GREEN};
  border-radius: 4px;
  padding: 5px 5px;
  margin-left: 5px;
  height: 100%;
  max-height: 25px;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  user-select: none;
  :hover {
    background-color: ${COLOR.LIGHT_GREEN};
  }
`;

const ButtonForfeit = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 10px;
  min-height: 25px;
  max-height: 25px;
  background-color: ${COLOR.RIVAL_RED};
  user-select: none;
  cursor: pointer;
  transition: all 0.25s ease 0s;
  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${COLOR.RIVAL_RED_2};
    }
  }
`;

const ButtonReady = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 10px;
  min-height: 25px;
  max-height: 25px;
  background-color: ${({ disabled }) => (disabled ? "#aaa" : COLOR.GREEN)};
  user-select: none;
  cursor: pointer;
  transition: all 0.25s ease 0s;
  @media (hover: hover) and (pointer: fine) {
    :hover {
      background-color: ${({ disabled }) =>
        disabled ? "#bbb" : COLOR.LIGHT_GREEN};
    }
  }
`;

const ButtonCancel = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px solid ${COLOR.WHITE};
  border-radius: 4px;
  margin-right: 5px;
  padding: 5px 5px;
  height: 100%;
  max-height: 25px;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  user-select: none;
  :hover {
    background-color: ${COLOR.RIVAL_RED};
    border-color: ${COLOR.RIVAL_RED};
  }
`;

const StatusBar = styled.div`
  display: flex;
  padding: 5px 0 10px;
  width: 100%;
  height: 35px;
  justify-content: space-between;
  align-items: center;
`;

const WrapStatusItems = styled.div`
  display: flex;
`;

const Matches = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
`;

const MatchesList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  background-color: ${COLOR.BG_ACCENT};

  /* width */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_ACCENT};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_SECONDARY};
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

const Match = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 8px;
  margin: 0 5px 5px;
  padding: 5px 3px 0;
  max-height: ${({ compact }) => (compact ? "32px" : "75px")};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  :hover {
    background-color: ${COLOR.BG_FOURTH};
  }
`;

const PlayerItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${COLOR.BG_SECONDARY};
  border-radius: 8px;
  margin: 0 5px 5px;
`;

const Position = styled.div`
  display: flex;
  border-radius: 5px;
  min-width: 18px;
  margin-right: 5px;
  background-color: ${COLOR.BG_INPUT};
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
`;

const Users = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
`;

const User = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 0 10px;
  :first-child {
    margin-right: 5px;
  }
  :last-child {
    margin-left: 5px;
  }
  :only-child {
    margin: 0 0 10px;
  }
`;

const Player = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLOR.BG_1};
  padding: 5px;
  border-radius: 8px;
  margin: 0 0 10px;
  margin-left: 10px;
  :last-child {
    margin-right: 10px;
  }
`;

const WrapPlayer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
`;

const WrapGame = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
`;

const Game = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 100px;

  padding: 3px 6px;
  border-radius: 12px 12px;

  overflow: hidden;
  background-color: ${COLOR.BG_INPUT};
`;

const WrapPlayers = styled.div`
  display: flex;
  flex-direction: ${({ compact }) => (compact ? "row" : "column")};
  justify-content: ${({ compact }) => (compact ? "center" : "flex-start")};
  flex: 1;
  padding: 0 20px;
`;

const Players = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  padding-top: 5px;

  /* width */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_ACCENT};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.BG_SECONDARY};
    border-radius: 6px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED};
  }
`;

const WrapAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  min-width: 18px;
  margin: 0 3px;
  background-color: ${COLOR.BG_INPUT};
`;

const Avatar = styled.img`
  width: 18px;
  height: 18px;
  object-fit: contain;
`;

const WrapName = styled.div`
  display: flex;
  align-items: center;
  flex-direction ${({ reverse }) => (reverse ? "row-reverse" : "row")};
`;

const Name = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  flex-wrap: nowrap;
`;

const WrapScore = styled.div`
  display: flex;
  align-items: center;
  flex-direction ${({ reverse }) => (reverse ? "row-reverse" : "row")};
`;

const Score = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  margin-left: 3px;
  background-color: ${({ outcome }) =>
    outcome === "W"
      ? COLOR.GREEN
      : outcome === "pending"
      ? COLOR.YELLOW
      : outcome === "L"
      ? COLOR.ROUGE
      : COLOR.BLUE};
  border-radius: 4px;
`;

const PlayerStatus = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  margin-left: 3px;
  padding: 2px 4px;
  background-color: ${({ ready }) => (ready ? COLOR.GREEN : COLOR.ROUGE)};
  border-radius: 4px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const ScoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 100%;
`;

const Entries = styled.div`
  display: flex;
  margin-top: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${COLOR.BG_PRIMARY};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${COLOR.RIVAL_RED};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.RIVAL_RED_2};
  }
`;

const WrapInput = styled.div`
  border-radius: 8px;
  display: flex;
  height: 30px;
  min-height: 30px;
  margin-top: 5px;
  width: 100%;
  box-shadow: 0px 8px 30px rgb(0 0 0 / 10%), 0px 3px 8px rgb(0 0 0 / 5%);
  background-color: ${COLOR.BG_SECONDARY};
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  color: #f2f2f2;
  overflow: hidden;
  transition: all 0.25s ease 0s;
  border: ${({ error }) =>
    error ? `2px solid #ed5f74` : "2px solid transparent"};
`;

const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  color: inherit;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  appearance: none;
  margin-left: 5px;
`;

const WrapNotes = styled.div`
  border-radius: 8px;
  display: flex;
  height: 30px;
  min-height: 30px;
  margin-bottom: 10px;
  width: 100%;
  box-shadow: 0px 8px 30px rgb(0 0 0 / 10%), 0px 3px 8px rgb(0 0 0 / 5%);
  background-color: ${COLOR.BG_SECONDARY};
  font-size: 15px;
  font-weight: 400;
  align-items: center;
  color: #f2f2f2;
  overflow: hidden;
  transition: all 0.25s ease 0s;
  border: ${({ error }) =>
    error ? `2px solid #ed5f74` : "2px solid transparent"};
`;

const Notes = styled.input`
  width: 100%;
  outline: none;
  border: none;
  color: inherit;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  appearance: none;
  margin-left: 5px;
`;

const Note = styled.div`
  width: 100%;
  outline: none;
  border: none;
  color: inherit;
  background-color: transparent;
  font-family: inherit;
  font-size: 14px;
  font-weight: inherit;
  line-height: inherit;
  appearance: none;
  margin: 10px 0 5px;
`; 

const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ed5f74;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  padding: 5px 0;
`;

const FullscreenBg = styled.div`
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0px;
  justify-content: center;
  align-items: center;
  cursor: zoom-out;
  color: #ffffff;
  font-size: 24px;
  z-index: 999;
`;
const FullscreenImage = styled.img`
  max-width: 100%;
  max-height: 80%;
`;
const FullscreenText = styled.div`
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background-color: ${COLOR.BLUE};
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const Loader = styled.svg`
  animation: ${rotate} 2s linear infinite;
  width: 15px;
  height: 15px;
`;

const Circle = styled.circle`
  stroke: #fff;
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
  fill: none;
  stroke-width: 8px;
`;

const SearchBar = styled.div`
  border-radius: 8px;
  z-index: 2;
  display: flex;
  height: 20px;
  min-height: 20px;
  width: calc(100% - 10px);
  box-shadow: 0px 8px 30px rgb(0 0 0 / 10%), 0px 3px 8px rgb(0 0 0 / 5%);
  background-color: ${COLOR.BG_SECONDARY};
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  color: #f2f2f2;
  padding: 13px 10px;
  overflow: hidden;
  transition: all 0.25s ease 0s;
  border: ${({ error }) =>
    error ? `2px solid #ed5f74` : "2px solid transparent"};
`;

const SearchIcon = styled(IconSearch)`
  width: 18px;
  height: 18px;
  fill: #f2f2f2;
`;

const pusher = new Pusher(process.env.REACT_APP_PUSHER_ID || "", {
  cluster: "us3",
  forceTLS: true,
});

const Panel = ({ action, addItem, removeItem, updateTournament, popout }) => {
  const [data, setData] = useState(null);
  const [channel, setChannel] = useState(null);
  const [isScoreOpen, setScoreOpen] = useState(null);
  const [isReadyOpen, setReadyOpen] = useState(null);
  const [isForfeitOpen, setForfeitOpen] = useState(null);
  const [forfeitPlayer, setForfeitPlayer] = useState(null);
  const [isMatchesOpen, setMatchesOpen] = useState(true);
  const [isPlayersOpen, setPlayersOpen] = useState(false);
  const [chat, setChat] = useState(false);
  const [submitting, setSubmitting] = useState(null);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [score, setScore] = useState(null);
  const [awayScore, setAwayScore] = useState(null);
  const [message, setMessage] = useState(null);
  const [hideModerator, setHideModerator] = useState(false);
  const [matchStatus, setMatchStatus] = useState([]);
  const [compact, setCompact] = useState(true);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (
      action?.status === "open" &&
      (action?.message === "Match Challenge" ||
        action?.message === "Review Score")
    ) {
      setScoreOpen("review");
    }
    if (action?.status === "open" && action?.message === "Review Score") {
      setError(null);
      data?.users?.forEach((u) => {
        if (u?.isHome) {
          setMessage(data?.message || u?.moderatorMessage?.message);
        } else {
          setMessage(u?.moderatorMessage?.message);
        }
      });
    }
  }, [action, data]);

  const handleMatchStatus = (status) => {
    let array = matchStatus;
    let index = matchStatus.indexOf(status);
    if (index > -1) {
      array.filter((s) => s !== status);
      setMatchStatus(matchStatus.filter((s) => s !== status));
    } else {
      setMatchStatus([...matchStatus, status]);
    }
  };

  const getMatch = (token) =>
    api.match
      .get(token)
      .then((resp) =>
        setData(Object.assign({}, { dataType: "match" }, resp?.data))
      );

  const getTournament = (token) =>
    api.tournament
      .get(token)
      .then((resp) =>
        setData(Object.assign({}, { dataType: "tournament" }, resp?.data))
      );

  const handleChat = () => {
    if (data?.dataType === "match") {
      api.match.update(data.token, { moderator: "closed" });
      removeItem(action);
    } else {
      api.tournament.update(data.id, { moderatorAt: new Date() });
    }
  };

  const readyPlayer = (ready, userId) => {
    setSubmitting(userId);
    api.match
      .update(data?.token, { ready: ready, userId: userId })
      .then(() => {
        setSubmitting(null);
        setReadyOpen(false);
      })
      .catch((e) => setSubmitting(null));
  };

  const handleForfeit = (userId) => {
    setSubmitting(userId);
    return api.match
      .update(data?.token, {
        forfeit: true,
        userId: userId,
      })
      .then((resp) => {
        setSubmitting(null);
        setForfeitOpen(null);
      })
      .catch((err) => {
        setSubmitting(null);
        setError(
          propValueOr(
            err,
            "response.data.message",
            err.message || "Unknown error. Please Try again later."
          )
        );
      });
  };

  const handleScore = () => {
    setSubmitting(true);
    api.match
      .update(data?.token, {
        userId: data?.users[0]?.id,
        score: data?.users?.length > 1 ? `${score} - ${awayScore}` : score,
        message: message,
        verified:
          data?.status === "challenged" || data?.status === "complete"
            ? undefined
            : true,
        matchType: data?.users?.length > 1 ? undefined : "ffa",
      })
      .then(() => {
        setSubmitting(null);
        setScoreOpen(null);
        if (data?.status === "pending" || data?.status === "challenged") {
          removeItem(action);
        }
      })
      .catch((err) => {
        setSubmitting(null);
        setError(err?.response?.data?.errors[0]?.message);
      });
  };

  useEffect(() => {
    let timer;
    if (action?.match?.token) {
      setChannel(
        action?.tournament?.token
          ? `${action.tournament.token}.${action?.match.token}`
          : action?.match.token
      );
      // Refresh the match data every 15 seconds
      getMatch(action?.match?.token);
      timer = setInterval(() => {
        getMatch(action?.match?.token);
      }, 15000);
      const c = pusher.subscribe(`match-${action?.match?.token}`);

      c.bind("reload", () => {
        getMatch(action?.match?.token);
      });
      c.bind("status", () => {
        getMatch(action?.match?.token);
      });
    } else if (action?.dataType === "chat" && action?.tournament?.token) {
      setChannel(`${action?.tournament?.token}.*`);
      // Refresh the tournament data every 15 seconds
      getTournament(action?.tournament?.token);
      timer = setInterval(() => {
        getTournament(action?.tournament?.token);
      }, 15000);
      const c = pusher.subscribe(
        `tournament-${parseTournamentId(action?.tournament?.id)}`
      );

      c.bind("status", () => {
        getTournament(action?.tournament?.token);
      });
    } else if (action?.dataType === "stream" && action?.match?.token) {
      getMatch(action?.match?.token);
      timer = setInterval(() => {
        getMatch(action?.match?.token);
      }, 15000);
    } else if (action?.tournament?.token) {
      setChannel(`tournament-${action?.tournament?.token}`);
      // Refresh the tournament data every 15 seconds
      getTournament(action?.tournament?.token);
      timer = setInterval(() => {
        getTournament(action?.tournament?.token);
      }, 15000);
      const c = pusher.subscribe(
        `tournament-${parseTournamentId(action?.tournament?.id)}`
      );

      c.bind("status", () => {
        getTournament(action?.tournament?.token);
      });
    }
    return () => clearInterval(timer);
  }, [action]);

  const createPopupWindow = (url, title, w, h) => {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : window.screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : window.screen.height;

    const left = (width - w) / 2 + dualScreenLeft;
    const top = (height - h) / 2 + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      "scrollbars=yes, location=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );

    // Puts focus on the newWindow
    if (window.focus) newWindow.focus();
    return newWindow;
  };

  const handlePopout = (d) => {
    createPopupWindow(
      `${window.location.origin}/popout/${d.dataType}/${d.token}`,
      `${d.dataType} ${d.token} ${new Date().toLocaleString()}`,
      760,
      578
    );
  };

  if (action?.dataType === "chat") {
    return (
      <Container>
        <Content>
          {data?.imageInfo && (
            <HeaderBackground src={data?.imageInfo?.background} />
          )}
          <Header>
            <Section>
              {data?.imageInfo || data?.tournament?.imageInfo ? (
                <>
                  <WrapImage>
                    <Image
                      src={
                        data?.imageInfo?.icon ||
                        data?.tournament?.imageInfo?.icon
                      }
                    />
                  </WrapImage>
                </>
              ) : (
                <WrapImage>
                  <Skeleton height={48} width={48} />
                </WrapImage>
              )}
              <Info>
                {data ? (
                  <WrapTitle>
                    <Title
                      tournament={data?.tournament}
                      onClick={() =>
                        data?.tournament &&
                        addItem({ tournament: data?.tournament })
                      }
                    >
                      {data?.dataType === "match"
                        ? `Game Id#: ${data?.token} ${
                            data?.tournament?.name
                              ? ` - ${data?.tournament?.name}`
                              : ""
                          }`
                        : `ID ${data?.id}: ${data?.name}`}
                    </Title>
                    <WrapExternalIcon
                      href={
                        data?.dataType === "match"
                          ? process.env.REACT_APP_DEVELOPMENT.includes("zelda")
                            ? `https://luigi.rivalgames.com/matches/${data?.token}`
                            : `https://rivalgames.com/matches/${data?.token}`
                          : process.env.REACT_APP_DEVELOPMENT.includes("zelda")
                          ? `https://luigi.rivalgames.com/tournaments/${data?.token}`
                          : `https://rivalgames.com/tournaments/${data?.token}`
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <ExternalIcon src={IconExternal} />
                    </WrapExternalIcon>
                  </WrapTitle>
                ) : (
                  <Title>
                    <Skeleton width={120} />
                  </Title>
                )}
                {data ? (
                  <Subtitle>
                    <Badge>CHAT MONITOR</Badge>
                    <WrapInfo>
                      {data?.organization && (
                        <WrapOrganization>
                          <Organization
                            src={data?.organization?.imageInfo?.icon}
                          />
                        </WrapOrganization>
                      )}
                      <WrapConsole>
                        {data?.console?.imageInfo ? (
                          <Console src={data?.console?.imageInfo?.thumbnail} />
                        ) : (
                          <CrossPlatformIcon />
                        )}
                      </WrapConsole>
                    </WrapInfo>
                  </Subtitle>
                ) : (
                  <Subtitle>
                    <Skeleton width={250} />
                  </Subtitle>
                )}
              </Info>
            </Section>
            <ButtonClose onClick={() => removeItem(action)}>
              <IconPlus width={19} />
            </ButtonClose>
          </Header>
          <Bar>
            <Button onClick={() => setHideModerator(!hideModerator)}>
              <Text>
                {hideModerator ? "Show Moderator Chat" : "Hide Moderator Chat"}
              </Text>
            </Button>
          </Bar>
        </Content>
        <WrapChat key={`panel-${channel}`}>
          <Chat
            key={channel}
            hideModerator={hideModerator}
            channel={channel}
            disabled
            height={"100%"}
          />
        </WrapChat>
      </Container>
    );
  }

  if (action?.dataType === "stream") {
    return (
      <Container>
        <Content>
          {data?.imageInfo && (
            <HeaderBackground src={data?.imageInfo?.background} />
          )}
          <Header>
            <Section>
              {data?.imageInfo || data?.tournament?.imageInfo ? (
                <>
                  <WrapImage>
                    <Image
                      src={
                        data?.imageInfo?.icon ||
                        data?.tournament?.imageInfo?.icon
                      }
                    />
                  </WrapImage>
                </>
              ) : (
                <WrapImage>
                  <Skeleton height={48} width={48} />
                </WrapImage>
              )}
              <Info>
                {data ? (
                  <WrapTitle>
                    <Title
                      tournament={data?.tournament}
                      onClick={() =>
                        data?.tournament &&
                        addItem({ tournament: data?.tournament })
                      }
                    >
                      {`Game Id#: ${data?.token} ${
                        data?.tournament?.name
                          ? ` - ${data?.tournament?.name}`
                          : ""
                      }`}
                    </Title>
                    <WrapExternalIcon
                      href={
                        data?.dataType === "match"
                          ? process.env.REACT_APP_DEVELOPMENT.includes("zelda")
                            ? `https://luigi.rivalgames.com/matches/${data?.token}`
                            : `https://rivalgames.com/matches/${data?.token}`
                          : process.env.REACT_APP_DEVELOPMENT.includes("zelda")
                          ? `https://luigi.rivalgames.com/tournaments/${data?.token}`
                          : `https://rivalgames.com/tournaments/${data?.token}`
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <ExternalIcon src={IconExternal} />
                    </WrapExternalIcon>
                  </WrapTitle>
                ) : (
                  <Title>
                    <Skeleton width={120} />
                  </Title>
                )}
                {data ? (
                  <Subtitle>
                    <Badge>STREAMS</Badge>
                    <WrapInfo>
                      {data?.organization && (
                        <WrapOrganization>
                          <Organization
                            src={data?.organization?.imageInfo?.icon}
                          />
                        </WrapOrganization>
                      )}
                      <WrapConsole>
                        {data?.console?.imageInfo ? (
                          <Console src={data?.console?.imageInfo?.thumbnail} />
                        ) : (
                          <CrossPlatformIcon />
                        )}
                      </WrapConsole>
                    </WrapInfo>
                  </Subtitle>
                ) : (
                  <Subtitle>
                    <Skeleton width={250} />
                  </Subtitle>
                )}
              </Info>
            </Section>
            <ButtonClose onClick={() => removeItem(action)}>
              <IconPlus width={19} />
            </ButtonClose>
          </Header>
        </Content>
        <WrapChat>
          <Streams game={data} />
        </WrapChat>
      </Container>
    );
  }

  const isSubmissions = isScoreOpen === 'submissions';

  return (
    <Container popout={popout}>
      {image && (
        <FullscreenBg onClick={() => setImage(null)}>
          <FullscreenText>{image?.message}</FullscreenText>
          <FullscreenImage src={image?.image} />
        </FullscreenBg>
      )}
      {video && (
        <FullscreenBg onClick={() => setVideo(null)}>
          <FullscreenText>{video?.message}</FullscreenText>
          <Video url={video?.video} onClose={() => setVideo(null)}/>
        </FullscreenBg>
      )}
      <Content>
        {data?.imageInfo && (
          <HeaderBackground src={data?.imageInfo?.background} />
        )}
        <Header>
          <Section>
            {data?.imageInfo || data?.tournament?.imageInfo ? (
              <>
                <WrapImage>
                  <Image
                    src={
                      data?.imageInfo?.icon || data?.tournament?.imageInfo?.icon
                    }
                  />
                </WrapImage>
              </>
            ) : (
              <WrapImage>
                <Skeleton height={48} width={48} />
              </WrapImage>
            )}
            <Info>
              {data ? (
                <WrapTitle>
                  <Title
                    tournament={data?.tournament}
                    onClick={() =>
                      data?.tournament &&
                      addItem({ tournament: data?.tournament })
                    }
                  >
                    {data?.dataType === "match"
                      ? `Game Id#: ${data?.token} ${
                          data?.tournament?.name
                            ? ` - ${data?.tournament?.name}`
                            : ""
                        }`
                      : `ID ${data?.id}: ${data?.name}`}
                  </Title>
                  <WrapExternalIcon
                    href={
                      data?.dataType === "match"
                        ? process.env.REACT_APP_DEVELOPMENT.includes("zelda")
                          ? `https://luigi.rivalgames.com/matches/${data?.token}`
                          : `https://rivalgames.com/matches/${data?.token}`
                        : process.env.REACT_APP_DEVELOPMENT.includes("zelda")
                        ? `https://luigi.rivalgames.com/tournaments/${data?.token}`
                        : `https://rivalgames.com/tournaments/${data?.token}`
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <ExternalIcon src={IconExternal} />
                  </WrapExternalIcon>
                </WrapTitle>
              ) : (
                <Title>
                  <Skeleton width={120} />
                </Title>
              )}
              {data ? (
                <Subtitle>
                  <Badge status={data?.status}>
                    {data?.type === "scheduled" &&
                    data?.status === "initialized" ? (
                      <WrapTimer>
                        STARTS IN &nbsp;
                        <Timer
                          doubleDigits
                          endDate={moment(data.startDate).toDate()}
                        />
                      </WrapTimer>
                    ) : data?.status === "initialized" &&
                      data?.dataType === "match" &&
                      data?.forfeitAt ? (
                      <WrapTimer>
                        INIT &nbsp;
                        <Timer
                          doubleDigits
                          endDate={moment(data.forfeitAt).toDate()}
                        />
                      </WrapTimer>
                    ) : data?.status === "playing" ? (
                      <WrapTimer>
                        PLAYING &nbsp;
                        <Timer
                          doubleDigits
                          startDate={moment(data.startedAt).toDate()}
                        />
                      </WrapTimer>
                    ) : (
                      data.status
                    )}
                  </Badge>
                  <WrapInfo>
                    {data?.organization && (
                      <WrapOrganization>
                        <Organization
                          src={data?.organization?.imageInfo?.icon}
                        />
                      </WrapOrganization>
                    )}
                    <WrapConsole>
                      {data?.console?.imageInfo ? (
                        <Console src={data?.console?.imageInfo?.thumbnail} />
                      ) : (
                        <CrossPlatformIcon />
                      )}
                    </WrapConsole>
                  </WrapInfo>
                </Subtitle>
              ) : (
                <Subtitle>
                  <Skeleton width={250} />
                </Subtitle>
              )}
            </Info>
          </Section>
          {!popout && (
            <>
              <ButtonWindow onClick={() => handlePopout(data)}>
                <WindowIcon />
              </ButtonWindow>
              <ButtonClose onClick={() => removeItem(action)}>
                <IconPlus width={19} />
              </ButtonClose>
            </>
          )}
        </Header>
        <Section>
          {data?.dataType === "match" && (
            <Users>
              {data?.users?.map((user) => (
                <Player key={`panel-match-user-${user?.id || user?.UserId}`}>
                  <WrapName>
                    <WrapAvatar>
                      <Avatar
                        src={
                          user.team
                            ? propValueOr(
                                user,
                                "team.imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            : propValueOr(
                                user,
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                        }
                        onError={(e) => (e.target.src = placeholderUserImg)}
                      />
                    </WrapAvatar>
                    <Name>
                      {propValueOr(user, "team.name", getUsername(user))}
                    </Name>
                  </WrapName>
                  <WrapScore>
                    {user?.score !== null && data?.status !== "initialized" ? (
                      <Score
                        outcome={
                          data?.users?.length === 1 &&
                          !user?.outcome &&
                          user?.score
                            ? "pending"
                            : undefined
                        }
                      >
                        {user?.score}
                      </Score>
                    ) : (
                      <PlayerStatus ready={user?.ready}>
                        {user?.ready ? "READY" : "NOT READY"}
                      </PlayerStatus>
                    )}
                    {user?.outcome && data?.users?.length > 1 ? (
                      <Score
                        outcome={
                          user?.outcome && data?.users?.length > 1
                            ? user?.outcome
                            : data?.users?.length === 1 &&
                              !user?.outcome &&
                              user?.score
                            ? "pending"
                            : undefined
                        }
                      >
                        {user?.outcome}
                      </Score>
                    ) : (
                      <></>
                    )}
                  </WrapScore>
                </Player>
              ))}
            </Users>
          )}
        </Section>
        {data &&
          (data?.dataType === "tournament" || data?.dataType === "match") && (
            <Bar>
              {data?.dataType === "tournament" &&
                data?.status !== "initialized" && (
                  <Button
                    onClick={() => {
                      setMatchesOpen(!isMatchesOpen);
                      setPlayersOpen(false);
                    }}
                  >
                    <Icon src={IconTrophy} />
                    <Text>View Matches</Text>
                  </Button>
                )}
              {data?.dataType === "tournament" && (
                <Button
                  onClick={() => {
                    setPlayersOpen(!isPlayersOpen);
                    setMatchesOpen(false);
                  }}
                >
                  <Icon src={IconPlayers} />
                  <Text>
                    {data?.playerCount}
                    {data?.maxPlayers && `/${data?.maxPlayers}`}
                    {" Players"}
                  </Text>
                </Button>
              )}
              {data?.dataType === "tournament" && (
                <Button
                  onClick={() => {
                    addItem({
                      tournament: data,
                      dataType: "chat",
                      id: `chat-${data?.token}`,
                    });
                  }}
                >
                  <Text>Chat Monitor</Text>
                </Button>
              )}
              {data?.dataType === "match" && (
                <Button
                  onClick={() => {
                    addItem({
                      match: { token: data?.token },
                      dataType: "stream",
                      id: `stream-${data?.token}`,
                    });
                  }}
                >
                  <Text>View Streams</Text>
                </Button>
              )}
              {data?.dataType === "match" && data?.status === "initialized" && (
                <Button onClick={() => setReadyOpen(!isReadyOpen)}>
                  <Icon src={IconThumb} />
                  <Text>Ready Player</Text>
                </Button>
              )}
              {data?.dataType === "match" &&
                (data?.status === "initialized" ||
                  data?.status === "playing") && (
                  <Button onClick={() => setForfeitOpen(!isForfeitOpen)}>
                    <Icon src={IconForfeit} />
                    <Text>Forfeit Player</Text>
                  </Button>
                )}
              {data?.dataType === "match" && data?.status === "pending" && (
                <Button
                  onClick={() => {
                    setScoreOpen(!isScoreOpen ? "review" : null);
                    setError(null);
                    data?.users?.forEach((u) => {
                      if (u?.isHome) {
                        setMessage(
                          data?.message || u?.moderatorMessage?.message
                        );
                      } else {
                        setMessage(u?.moderatorMessage?.message);
                      }
                    });
                  }}
                >
                  <Icon src={IconReview} />
                  <Text>Review Score</Text>
                </Button>
              )}
              {data?.dataType === "match" && data?.status === "complete" && (
                <Button
                  onClick={() => {
                    setScoreOpen(!isScoreOpen ? "change" : null);
                    setError(null);
                    data?.users?.forEach((u) => {
                      if (u?.isHome) {
                        setMessage(
                          data?.message || u?.moderatorMessage?.message
                        );
                      } else {
                        setMessage(u?.moderatorMessage?.message);
                      }
                    });
                  }}
                >
                  <SwapIcon src={IconSwap} />
                  <Text>Change Score</Text>
                </Button>
              )}
              {data?.dataType === "match" && data?.status === "challenged" && (
                <Button
                  onClick={() => {
                    setScoreOpen(!isScoreOpen ? "review" : null);
                    setError(null);
                  }}
                >
                  <ChallengeIcon src={IconChallenge} />
                  <Text>Challenged </Text>
                  <WrapTimer>
                    <Timer
                      doubleDigits
                      endDate={moment(data.updatedAt)
                        .add(15, "minutes")
                        .toDate()}
                    />
                  </WrapTimer>
                </Button>
              )}
              {data?.dataType === "match" && data?.status !== 'initialized' && (
                <Button
                  onClick={() => {
                    setScoreOpen(!isScoreOpen ? "submissions" : null);
                    setError(null);
                  }}
                >
                  <Text>View Submissions</Text>
                </Button>
              )}
              {(data?.status === "initialized" ||
                data?.status === "in progress") &&
                data?.dataType === "tournament" && (
                  <>
                    {!data?.paused ? (
                      <Button
                        onClick={() =>
                          updateTournament(data?.id, { paused: new Date() })
                        }
                      >
                        <PauseIcon />
                        <Text>Pause Tournament</Text>
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          updateTournament(data?.id, { paused: null })
                        }
                      >
                        <PlayIcon />
                        <WrapTimer>
                          <Timer
                            doubleDigits
                            startDate={new Date(data?.paused).getTime()}
                          />
                        </WrapTimer>
                      </Button>
                    )}
                  </>
                )}
              {data.moderatorAt && (
                <Button onClick={() => handleChat()}>
                  <FlagIcon src={IconFlag} />
                  <Text>End Chat</Text>
                </Button>
              )}
            </Bar>
          )}
      </Content>
      <Accordion
        collapsed={!chat}
        isOpen={
          isMatchesOpen &&
          data?.dataType === "tournament" &&
          data?.status !== "initialized"
        }
      >
        {isMatchesOpen &&
          data?.dataType === "tournament" &&
          data?.status !== "initialized" && (
            <Matches>
              <StatusBar>
                <WrapStatusItems>
                  {matchStatus?.length > 0 && (
                    <ButtonStatus onClick={() => setMatchStatus([])}>
                      x
                    </ButtonStatus>
                  )}
                  {[
                    "initialized",
                    "playing",
                    "pending",
                    "challenged",
                    "complete",
                    "cancelled",
                  ]?.map((s) => (
                    <ButtonStatus
                      key={s}
                      status={s}
                      active={matchStatus.includes(s)}
                      onClick={() => handleMatchStatus(s)}
                    >
                      {s}
                    </ButtonStatus>
                  ))}
                </WrapStatusItems>
                <CompactIcon onClick={() => setCompact(!compact)} />
              </StatusBar>
              <SearchBar>
                <SearchIcon />
                <Input
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  placeholder="Search by username or by score (enter single # or #-#)"
                  type="text"
                  value={query}
                />
                {query && (
                  <ButtonStatus onClick={() => setQuery("")}>
                    CLEAR
                  </ButtonStatus>
                )}
              </SearchBar>
              <MatchesList>
                {data?.matches
                  ?.filter((m) =>
                    matchStatus?.length > 0
                      ? matchStatus?.includes(m?.status)
                      : true
                  )
                  ?.filter((m) => {
                    if (query?.includes("-")) {
                      const [home, away] = query?.split("-");
                      return (
                        `${home}` === `${m?.users?.[0]?.score}` ||
                        `${home}` === `${m?.users?.[1]?.score}` ||
                        `${away}` === `${m?.users?.[0]?.score}` ||
                        `${away}` === `${m?.users?.[1]?.score}`
                      );
                    }
                    return m?.users?.some(
                      (u) =>
                        u?.username
                          ?.toLowerCase()
                          .includes(query?.toLowerCase()) ||
                        u?.score?.toString().includes(query)
                    );
                  })
                  ?.sort((a, b) => {
                    if (a.stage === b.stage) {
                      if (a.round === b.round) {
                        return b?.users[0]?.score - a?.users[0]?.score;
                      }
                      return b.round - a.round;
                    }
                    return b.stage - a.stage;
                  })
                  .map((match) => (
                    <Match
                      compact={compact}
                      key={`panel-match-${match?.token}`}
                      onClick={() =>
                        addItem({ match: match, tournament: data })
                      }
                    >
                      <WrapGame>
                        {!compact && <Game>{`Game Id#: ${match?.token}`}</Game>}
                        {compact && (
                          <WrapPlayers compact>
                            {match?.users
                              ?.sort((a, b) => {
                                if (a.outcome === "W") {
                                  return -1;
                                }
                                if (b.outcome === "W") {
                                  return 1;
                                }
                                return 0;
                              })
                              .map((user, i) => (
                                <WrapPlayer
                                  key={`panel-match-player-${
                                    user?.id || user?.UserId
                                  }`}
                                  reverse={i === 1}
                                >
                                  <WrapName reverse={i === 1}>
                                    <WrapAvatar>
                                      <Avatar
                                        src={
                                          user.team
                                            ? propValueOr(
                                                user,
                                                "team.imageInfo.thumbnail",
                                                placeholderUserImg
                                              )
                                            : propValueOr(
                                                user,
                                                "imageInfo.thumbnail",
                                                placeholderUserImg
                                              )
                                        }
                                        onError={(e) =>
                                          (e.target.src = placeholderUserImg)
                                        }
                                      />
                                    </WrapAvatar>
                                    <Name>
                                      {propValueOr(
                                        user,
                                        "team.name",
                                        getUsername(user)
                                      )}
                                    </Name>
                                  </WrapName>
                                  <WrapScore reverse={i === 1}>
                                    {user?.score !== null &&
                                    match?.status !== "initialized" &&
                                    match?.status !== "challenged" ? (
                                      <Score
                                        outcome={
                                          match?.users?.length === 1 &&
                                          !user?.outcome &&
                                          user?.score
                                            ? "pending"
                                            : undefined
                                        }
                                      >
                                        {user?.score}
                                      </Score>
                                    ) : (
                                      match?.status !== "challenged" && (
                                        <PlayerStatus ready={user?.ready}>
                                          {user?.ready ? "READY" : "NOT READY"}
                                        </PlayerStatus>
                                      )
                                    )}
                                    {user?.outcome &&
                                    match?.users?.length > 1 ? (
                                      <Score
                                        outcome={
                                          user?.outcome &&
                                          match?.users?.length > 1
                                            ? user?.outcome
                                            : match?.users?.length === 1 &&
                                              !user?.outcome &&
                                              user?.score
                                            ? "pending"
                                            : undefined
                                        }
                                      >
                                        {user?.outcome}
                                      </Score>
                                    ) : (
                                      <></>
                                    )}
                                  </WrapScore>
                                </WrapPlayer>
                              ))}
                          </WrapPlayers>
                        )}
                        <WrapRound>
                          <StreamsIcon
                            onClick={() => {
                              addItem({
                                match: { token: match?.token },
                                dataType: "stream",
                                id: `stream-${match?.token}`,
                              });
                            }}
                          />
                          {match?.stage > 1 && (
                            <Round>
                              {compact
                                ? `S${match?.stage}`
                                : `Stage ${match?.stage}`}
                            </Round>
                          )}
                          <Round>
                            {compact
                              ? `R${match?.round}`
                              : `Round ${match?.round}`}
                          </Round>
                          <GameStatus status={match?.status}>
                            {match?.status?.substring(0, 4)}
                            {match?.status === "initialized" &&
                            match?.forfeitAt ? (
                              <WrapTimer>
                                &nbsp;
                                <Timer
                                  doubleDigits
                                  endDate={moment(match.forfeitAt).toDate()}
                                />
                              </WrapTimer>
                            ) : match?.status === "playing" ? (
                              <WrapTimer>
                                &nbsp;
                                <Timer
                                  doubleDigits
                                  startDate={moment(match.startedAt).toDate()}
                                />
                              </WrapTimer>
                            ) : (
                              <></>
                            )}
                          </GameStatus>
                        </WrapRound>
                      </WrapGame>
                      {!compact && (
                        <WrapPlayers>
                          {match?.users?.map((user) => (
                            <WrapPlayer
                              key={`panel-match-player-${
                                user?.id || user?.UserId
                              }`}
                            >
                              <WrapName>
                                <WrapAvatar>
                                  <Avatar
                                    src={
                                      user.team
                                        ? propValueOr(
                                            user,
                                            "team.imageInfo.thumbnail",
                                            placeholderUserImg
                                          )
                                        : propValueOr(
                                            user,
                                            "imageInfo.thumbnail",
                                            placeholderUserImg
                                          )
                                    }
                                    onError={(e) =>
                                      (e.target.src = placeholderUserImg)
                                    }
                                  />
                                </WrapAvatar>
                                <Name>
                                  {propValueOr(
                                    user,
                                    "team.name",
                                    getUsername(user)
                                  )}
                                </Name>
                              </WrapName>
                              <WrapScore>
                                {user?.score !== null &&
                                match?.status !== "initialized" &&
                                match?.status !== "challenged" ? (
                                  <Score
                                    outcome={
                                      match?.users?.length === 1 &&
                                      !user?.outcome &&
                                      user?.score
                                        ? "pending"
                                        : undefined
                                    }
                                  >
                                    {user?.score}
                                  </Score>
                                ) : (
                                  match?.status !== "challenged" && (
                                    <PlayerStatus ready={user?.ready}>
                                      {user?.ready ? "READY" : "NOT READY"}
                                    </PlayerStatus>
                                  )
                                )}
                                {user?.outcome && match?.users?.length > 1 ? (
                                  <Score
                                    outcome={
                                      user?.outcome && match?.users?.length > 1
                                        ? user?.outcome
                                        : match?.users?.length === 1 &&
                                          !user?.outcome &&
                                          user?.score
                                        ? "pending"
                                        : undefined
                                    }
                                  >
                                    {user?.outcome}
                                  </Score>
                                ) : (
                                  <></>
                                )}
                              </WrapScore>
                            </WrapPlayer>
                          ))}
                        </WrapPlayers>
                      )}
                    </Match>
                  ))}
              </MatchesList>
            </Matches>
          )}
      </Accordion>
      <Accordion collapsed={!chat} isOpen={isPlayersOpen}>
        {isPlayersOpen && (
          <Players>
            {data?.users?.map((user) => (
              <PlayerItem
                key={`panel-tournament-player-${user?.id || user?.UserId}`}
              >
                <WrapPlayer>
                  <WrapName>
                    <Position>{user?.seed}</Position>
                    <WrapAvatar>
                      <Avatar
                        src={
                          user.team
                            ? propValueOr(
                                user,
                                "team.imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            : propValueOr(
                                user,
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                        }
                        onError={(e) => (e.target.src = placeholderUserImg)}
                      />
                    </WrapAvatar>
                    <Name>
                      {propValueOr(user, "team.name", getUsername(user))}
                    </Name>
                  </WrapName>
                  {data?.checkinStart && (
                    <PlayerStatus
                      onClick={() =>
                        api.tournament.users(data?.id, { checkin: user.UserId })
                      }
                      ready={user?.checkin}
                    >
                      {user?.checkin ? "CHECKED IN" : "NOT CHECKED IN"}
                    </PlayerStatus>
                  )}
                </WrapPlayer>
              </PlayerItem>
            ))}
          </Players>
        )}
      </Accordion>
      <Accordion
        collapsed={!chat}
        isOpen={
          isForfeitOpen &&
          (data?.status === "initialized" || data?.status === "playing")
        }
      >
        {isForfeitOpen && (
          <ScoreInfo>
            <Users>
              {data?.users?.map((user, i) => (
                <User key={`panel-forfeit-user-${user?.id || user?.UserId}`}>
                  <WrapName>
                    <WrapAvatar>
                      <Avatar
                        src={
                          user.team
                            ? propValueOr(
                                user,
                                "team.imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            : propValueOr(
                                user,
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                        }
                        onError={(e) => (e.target.src = placeholderUserImg)}
                      />
                    </WrapAvatar>
                    <Name>
                      {propValueOr(user, "team.name", getUsername(user))}
                    </Name>
                  </WrapName>
                  {forfeitPlayer && forfeitPlayer?.id === user?.id ? (
                    <>
                      <ButtonForfeit onClick={() => setForfeitPlayer(null)}>
                        <Text>Cancel</Text>
                      </ButtonForfeit>
                      <ButtonForfeit
                        onClick={() => {
                          if (submitting !== forfeitPlayer?.id) {
                            handleForfeit(forfeitPlayer?.id).then(() =>
                              setForfeitPlayer(null)
                            );
                          }
                        }}
                      >
                        {isReadyOpen && submitting ? (
                          <Loader viewBox="0 0 50 50">
                            <Circle cx="25" cy="25" r="20"></Circle>
                          </Loader>
                        ) : (
                          <Text>
                            Forfeit{" "}
                            {propValueOr(user, "team.name", getUsername(user))}
                          </Text>
                        )}
                      </ButtonForfeit>
                    </>
                  ) : (
                    <ButtonForfeit onClick={() => setForfeitPlayer(user)}>
                      <Text>Forfeit Player</Text>
                    </ButtonForfeit>
                  )}
                </User>
              ))}
            </Users>
          </ScoreInfo>
        )}
      </Accordion>
      <Accordion
        collapsed={!chat}
        isOpen={isReadyOpen && data?.status === "initialized"}
      >
        {isReadyOpen && (
          <ScoreInfo>
            <Users>
              {data?.users?.map((user) => (
                <User key={`panel-ready-user-${user?.id || user?.UserId}`}>
                  <WrapName>
                    <WrapAvatar>
                      <Avatar
                        src={
                          user.team
                            ? propValueOr(
                                user,
                                "team.imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            : propValueOr(
                                user,
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                        }
                        onError={(e) => (e.target.src = placeholderUserImg)}
                      />
                    </WrapAvatar>
                    <Name>
                      {propValueOr(user, "team.name", getUsername(user))}
                    </Name>
                  </WrapName>
                  <ButtonReady
                    disabled={user?.ready}
                    onClick={() =>
                      submitting === user?.id
                        ? null
                        : user?.ready
                        ? readyPlayer(false, user?.id)
                        : readyPlayer(true, user?.id)
                    }
                  >
                    {isReadyOpen && submitting ? (
                      <Loader viewBox="0 0 50 50">
                        <Circle cx="25" cy="25" r="20"></Circle>
                      </Loader>
                    ) : (
                      <Text>
                        {user?.ready ? "Unready Player" : "Ready Player"}
                      </Text>
                    )}
                  </ButtonReady>
                </User>
              ))}
            </Users>
          </ScoreInfo>
        )}
      </Accordion>
      <Accordion collapsed={!chat} isOpen={isScoreOpen}>
        {isScoreOpen && (
          <ScoreInfo>
            <Users>
              {data?.users?.map((user) => (
                <User key={`panel-score-user-${user?.id || user?.UserId}`}>
                  <WrapName>
                    <WrapAvatar>
                      <Avatar
                        src={
                          user.team
                            ? propValueOr(
                                user,
                                "team.imageInfo.thumbnail",
                                placeholderUserImg
                              )
                            : propValueOr(
                                user,
                                "imageInfo.thumbnail",
                                placeholderUserImg
                              )
                        }
                        onError={(e) => (e.target.src = placeholderUserImg)}
                      />
                    </WrapAvatar>
                    <Name>
                      {propValueOr(user, "team.name", getUsername(user))}
                    </Name>
                  </WrapName>
                  {!isSubmissions && <WrapInput>
                    <Input
                      onChange={(e) => {
                        user?.isHome
                          ? setScore(e.target.value)
                          : setAwayScore(e.target.value);
                      }}
                      placeholder="Enter new score for player"
                      type="text"
                      value={user?.isHome ? score || "" : awayScore || ""}
                    />
                  </WrapInput>}
                  {user?.scores?.submitted && user?.submitted && (
                    <WrapSubmitted
                      onClick={() => {
                        if (isSubmissions) {return;}
                        const [submittedHomeScore, submittedAwayScore] =
                          user?.scores?.submitted?.toString().split(" - ");
                        setScore(submittedHomeScore);
                        setAwayScore(submittedAwayScore);
                      }}
                    >
                      {data?.users?.map((u, i) => {
                        const [submittedHomeScore, submittedAwayScore] =
                          user?.scores?.submitted?.toString().split(" - ");
                        return (
                          <Row
                            key={`panel-score-submitted-user-${
                              u?.id || user?.UserId
                            }-${i}`}
                          >
                            <WrapName>
                              <WrapAvatar>
                                <Avatar
                                  src={
                                    u.team
                                      ? propValueOr(
                                          u,
                                          "team.imageInfo.thumbnail",
                                          placeholderUserImg
                                        )
                                      : propValueOr(
                                          u,
                                          "imageInfo.thumbnail",
                                          placeholderUserImg
                                        )
                                  }
                                  onError={(e) =>
                                    (e.target.src = placeholderUserImg)
                                  }
                                />
                              </WrapAvatar>
                              <Name>
                                {propValueOr(u, "team.name", getUsername(u))}
                              </Name>
                            </WrapName>
                            <WrapScore>
                              <RowScore
                                outcome={
                                  submittedHomeScore === submittedAwayScore
                                    ? "T"
                                    : i === 0
                                    ? submittedHomeScore > submittedAwayScore
                                      ? "W"
                                      : ":"
                                    : submittedHomeScore < submittedAwayScore
                                    ? "W"
                                    : "L"
                                }
                              >
                                {i === 0
                                  ? submittedHomeScore
                                  : submittedAwayScore}
                              </RowScore>
                            </WrapScore>
                          </Row>
                        );
                      })}
                    </WrapSubmitted>
                  )}
                  {JSON.parse(user?.message || '{}')?.notes && <Note><b>Note:</b> {JSON.parse(user?.message || '{}')?.notes}</Note>}
                  <Entries>
                    {user?.verificationEntries
                      ?.filter((e) => e?.video || e?.image || e?.message)
                      .map((entry, i) => (
                        <WrapUpload
                          key={`panel-entry-${i}`}
                          onClick={() => entry?.video ? setVideo(entry) : setImage(entry)}
                          {...(entry?.video && {style: {backgroundColor: '#666', cursor: 'pointer'}})}
                        >
                          {entry?.video 
                          ? <IconVideo width={25} height={25}/>
                          : <Upload src={entry?.image} />}
                        </WrapUpload>
                      ))}
                  </Entries>
                </User>
              ))}
            </Users>
            {!isSubmissions && <WrapNotes>
              <Notes
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Moderator entered notes (optional)..."
                type="text"
                value={message}
              />
            </WrapNotes>}
            <Buttons>
              <ButtonCancel onClick={() => setScoreOpen(null)}>
                <Text>{isSubmissions ? 'Close' : 'Cancel'}</Text>
              </ButtonCancel>
              {!isSubmissions && <ButtonSubmit onClick={() => handleScore()}>
                {submitting ? (
                  <Loader viewBox="0 0 50 50">
                    <Circle cx="25" cy="25" r="20"></Circle>
                  </Loader>
                ) : (
                  <Text>
                    {data?.users[0]?.scores?.submitted ===
                      (awayScore ? `${score} - ${awayScore}` : score) ||
                    (!score && !awayScore)
                      ? "Confirm score"
                      : "Submit new score"}
                  </Text>
                )}
              </ButtonSubmit>}
            </Buttons>
            <Error>{error}</Error>
          </ScoreInfo>
        )}
      </Accordion>
      {data && channel && (
        <>
          <WrapChevron onClick={() => setChat(!chat)}>
            <ChevronIcon collapsed={!chat} src={IconChevronDown} />
          </WrapChevron>
          <WrapChat collapsed={chat} key={`panel-${channel}`}>
            <Chat
              key={channel}
              channel={channel}
              height={"100%"}
              status={data.status}
              startedAt={data.createdAt}
              endedAt={data.endedAt}
            />
          </WrapChat>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  addItem: addPanelItem,
  removeItem: removePanelItem,
  updateTournament: updateTournamentAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Panel);
