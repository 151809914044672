import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { parseJwt } from "../helpers/common";
import {
  setAuthorizedUserInfoAction,
  setUserAsNotAuthorizedAction,
  updateTokensAction,
  updateUserRequestStatus,
} from "../store/user/actions";
import AppRouting from "./AppRouting/AppRouting";
import { SkeletonTheme } from "react-loading-skeleton";
import { COLOR } from "../constants/theme";

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const App = ({
  user,
  location,
  setAuthorizedUserInfo,
  setUserAsNotAuthorized,
  updateTokens,
  updateRequestStatus,
}) => {
  useEffect(() => {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      setAuthorizedUserInfo(parseJwt(token));
      updateTokens(); //on mount, get most recent jwt
    } else {
      setUserAsNotAuthorized();
    }
  }, [setAuthorizedUserInfo, setUserAsNotAuthorized, updateTokens]);

  useEffect(() => {
    const requestStatus = user.requestStatus;

    // reset request status when user goes to another page
    if (!!requestStatus) {
      updateRequestStatus(null);
    }
  }, [user, location, updateRequestStatus]);

  const curPath = location.pathname;

  if (!user?.verified) {
    return <Container />;
  }

  return (
    <SkeletonTheme color={COLOR.BG_PRIMARY} highlightColor={COLOR.BG_SECONDARY}>
      <Container>
        <AppRouting user={user} curPath={curPath} />
      </Container>
    </SkeletonTheme>
  );
};

const mapStateToProps = (state) => ({
  user: state.userState,
});

const mapDispatchToProps = {
  setAuthorizedUserInfo: setAuthorizedUserInfoAction,
  setUserAsNotAuthorized: setUserAsNotAuthorizedAction,
  updateTokens: updateTokensAction,
  updateRequestStatus: updateUserRequestStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
