import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import API from "../api";
import dynamicMiddlewares, { addMiddleware } from "redux-dynamic-middlewares";
import userReducer from "./user/reducers";
import actionReducer from "./action/reducers";
import searchReducer from "./search/reducers";
import tournamentReducer from "./tournament/reducers";
import panelReducer from "./panel/reducers";
import { loadState, saveState } from "./localStorage";

const persistedState = loadState();

const appReducer = combineReducers({
  actionState: actionReducer,
  userState: userReducer,
  panelState: panelReducer,
  tournamentState: tournamentReducer,
  searchState: searchReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER/LOGOUT_SUCCESS") {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(dynamicMiddlewares)
);

store.subscribe(() => {
  saveState({
    searchState: store.getState().searchState,
  });
});

export const api = new API(store);

addMiddleware(thunk.withExtraArgument({ api }));

export default store;
