import { TOURNAMENT_ACTIONS } from "./constants";

export const updateTournamentAction =
  (id, body) =>
  (dispatch, getState, { api }) => {
    dispatch({ type: TOURNAMENT_ACTIONS.update.request });
    return api.tournament
      .update(id, body)
      .then((resp) => {
        dispatch({
          type: TOURNAMENT_ACTIONS.update.success,
          payload: resp.data,
        });
        return resp;
      })
      .catch((err) => {
        dispatch({ type: TOURNAMENT_ACTIONS.update.error });
        throw err;
      });
  };
