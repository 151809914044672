import { SEARCH_ACTIONS } from "./constants";

export const searchAction =
  (query, status) =>
  (dispatch, getState, { api }) =>
    api
      .search(query, status)
      .then((resp) => {
        dispatch({
          type: SEARCH_ACTIONS.get.success,
          payload: { ...resp.data, status: status },
        });
        return resp;
      })
      .catch((err) => {
        dispatch({ type: SEARCH_ACTIONS.get.error });
        throw err;
      });

export const resetSearchAction = () => (dispatch) =>
  dispatch({ type: SEARCH_ACTIONS.reset });

export const addSearchItem =
  (data) =>
  (dispatch, getState, { api }) =>
    dispatch({ type: SEARCH_ACTIONS.add, payload: data });

export const removeSearchItem =
  (data) =>
  (dispatch, getState, { api }) =>
    dispatch({ type: SEARCH_ACTIONS.remove, payload: data });
