import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import Panel from "../dashboard/Panel/Panel";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const Popout = ({ match: { params } }) => {
  return (
    <Container>
      <Panel popout action={{ [params.type]: { token: params.token } }} />
    </Container>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Popout));
