import { SEARCH_ACTIONS } from "./constants";

const initialState = {
  items: [],
  docs: [],
  page: 1,
  total: 1,
  isFetching: false,
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ACTIONS.add:
      if (
        action.payload?.id &&
        state.items.map((i) => i.id).includes(action.payload?.id)
      ) {
        return state;
      } else if (
        action.payload?.name &&
        !action.payload?.organization &&
        state.items.map((i) => i.name).includes(action.payload?.name)
      ) {
        return state;
      }
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case SEARCH_ACTIONS.remove:
      return {
        ...state,
        items: state.items.filter((item) =>
          item?.id
            ? item?.id !== action.payload?.id
            : item?.name !== action.payload?.name
        ),
      };
    case SEARCH_ACTIONS.get.request:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_ACTIONS.get.success:
      let docs = [];
      if (action.payload.status) {
        docs = action.payload.docs;
      } else {
        docs = [
          {
            id: null,
            imageInfo: {
              icon: "https://rivalgames.s3.amazonaws.com/staticImages/rival-shield-logo.png",
            },
            name: "All Organizations",
          },
          ...(action.payload.docs || []),
        ];
      }
      return {
        ...state,
        isFetching: false,
        docs: docs,
        page: action.payload.page,
        total: action.payload.total,
      };
    case SEARCH_ACTIONS.get.error:
      return {
        ...state,
        docs: [],
        page: 1,
        total: 1,
        isFetching: false,
        error: action.payload,
      };
    case SEARCH_ACTIONS.reset:
      return {
        ...state,
        docs: [],
        page: 1,
        total: 1,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default reducers;
