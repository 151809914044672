import moment from "moment";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { COLOR, FONT_FAMILY } from "../../../../constants/theme";
import { addPanelItem } from "../../../../store/panel/actions";

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%ds",
    m: "a minute",
    mm: "%dm",
    h: "an hour",
    hh: "%dhr",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const Container = styled.div`
  border-radius: 14px;
  display: flex;
  width: 100%;
  min-height: 55px;
  height: 55px;
  margin-bottom: 10px;
  background-color: ${COLOR.BG_SECONDARY};
  align-items: center;
  color: #f2f2f2;
  padding: 0px 10px;
  position: relative;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  user-select: none;
  :hover {
    background-color: ${COLOR.BG_THIRD};
  }
`;

const WrapImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  min-width: 36px;
  min-height: 36px;
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  object-fit: contain;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Date = styled.div`
  display: flex;
  font-family: ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT_4};
  font-weight: 300;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  display: flex;
  font-family: ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT};
  font-weight: 600;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${FONT_FAMILY.CONDENSED};
  color: ${COLOR.TEXT};
  font-weight: 400;
  width: 250px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2px;
`;

const WrapOrganization = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 5px;
`;

const Organization = styled.img`
  max-height: 13px;
  object-fit: contain;
`;

const Item = ({ data, addItem }) => {
  const [date, setDate] = useState(
    moment(
      data?.status === "closed" ? data?.updatedAt : data?.createdAt
    ).fromNow()
  );
  useEffect(() => {
    const intrvl = setInterval(
      () =>
        setDate(
          moment(
            data?.status === "closed" ? data?.updatedAt : data?.createdAt
          ).fromNow()
        ),
      1000
    );

    // returned function will be called on component unmount
    return () => {
      clearInterval(intrvl);
    };
  }, [data]);
  return (
    <Container onClick={() => addItem(data)}>
      <WrapImage>
        <Image src={data?.tournament?.imageInfo?.icon} />
      </WrapImage>
      <Info>
        <Header>
          <Title>{data?.message}</Title>
          <Date>{date}</Date>
        </Header>
        <Subtitle>
          {data?.organization && (
            <WrapOrganization>
              <Organization src={data?.organization?.imageInfo?.icon} />
            </WrapOrganization>
          )}
          {data?.match?.token
            ? `Game Id#: ${data?.match?.token}`
            : `${data?.tournament?.name || data?.name} `}
        </Subtitle>
      </Info>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  items: state.panelState.items,
});

const mapDispatchToProps = {
  addItem: addPanelItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Item);
